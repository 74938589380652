import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./index.scss";

import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import AccountingPage from "./pages/AccountingPage";
import LegalPage from "./pages/LegalPage";
import OffersPage from "./pages/OffersPage";
import HRPage from "./pages/HRPage";
import CorporatePage from "./pages/CorporatePage";
import AllServicesPage from "./pages/AllServicesPage";
import AboutUsPage from "./pages/AboutUsPage";
import PolicyPage from "./pages/PolicyPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/corporate",
    element: <CorporatePage />,
  },
  {
    path: "/accounting",
    element: <AccountingPage />,
  },
  {
    path: "/legal",
    element: <LegalPage />,
  },
  {
    path: "/hr",
    element: <HRPage />,
  },
  {
    path: "/offers",
    element: <OffersPage />,
  },
  {
    path: "/all",
    element: <AllServicesPage />,
  },
  {
    path: "/about",
    element: <AboutUsPage />,
  },
  {
    path: "/policy",
    element: <PolicyPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <RouterProvider router={router} />
  </>
);
