import React from "react";

import styles from "./Header.module.scss";

import { Link } from "react-router-dom";

import HeaderTablet from "./HeaderTablet";
import HeaderMobile from "./HeaderMobile";
import HeaderDesktop from "./HeaderDesktop";
import Modal from "../../UI/Modal/Modal";

export interface IService {
  corporate: boolean;
  legal: boolean;
  accounting: boolean;
  hr: boolean;
  offers: boolean;
  contacts: boolean;
}

const Header: React.FC = () => {
  const [isModalActive, setIsModalActive] = React.useState(false);
  React.useEffect(() => {
    if (isModalActive) {
      document.body.classList.add("lock");
    } else {
      document.body.classList.remove("lock");
    }

    return () => {
      document.body.classList.remove("lock");
    };
  }, [isModalActive]);

  const [scrollY, setScrollY] = React.useState(0);
  React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isMenuActive, setIsMenuActive] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState<IService>({
    corporate: false,
    legal: false,
    accounting: false,
    hr: false,
    offers: false,
    contacts: false,
  });
  React.useEffect(() => {
    const main = document.querySelector("main");
    if (isMenuActive) {
      document.body.classList.add("lock");
      main?.classList.add("header-menu-active");
    } else {
      document.body.classList.remove("lock");
      main?.classList.remove("header-menu-active");
    }

    return () => {
      document.body.classList.remove("lock");
      main?.classList.remove("header-menu-active");
    };
  }, [isMenuActive]);

  return (
    <header
      className={`${
        scrollY > 0
          ? isMenuActive
            ? `${styles.header} ${styles.scrolled} ${styles.active}`
            : `${styles.header} ${styles.scrolled}`
          : styles.header
      }`}
    >
      <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" />
      <div className="container">
        <div className={isMenuActive ? `${styles.body} ${styles.active}` : styles.body}>
          <Link to="/">
            <h1 className={styles.left}>
              <img src="/images/header/logo.svg" alt="ЛексЛегал" />
            </h1>
          </Link>
          <div className={styles.right}>
            <div onClick={() => setIsModalActive(true)} className={styles.consult}>
              <Link to="">Получить консультацию</Link>
            </div>
            {/* TEMPORARY <div className={styles.personal}>
              <Link to="">Войти в личный кабинет</Link>
            </div> */}
            <div
              onClick={() => setIsMenuActive(!isMenuActive)}
              className={isMenuActive ? `${styles.burger} ${styles.active}` : styles.burger}
            >
              <span />
            </div>
          </div>
        </div>
        <HeaderDesktop
          isMenuActive={isMenuActive}
          setIsMenuActive={setIsMenuActive}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
        <HeaderTablet
          isMenuActive={isMenuActive}
          setIsMenuActive={setIsMenuActive}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
        <HeaderMobile
          isMenuActive={isMenuActive}
          setIsMenuActive={setIsMenuActive}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
      </div>
    </header>
  );
};

export default Header;
