import React from "react";

import Layout from "../components/Layout/Layout";
import BusinessReg from "../components/pages/Corporate/BusinessReg/BusinessReg";
import BusinessReorg from "../components/pages/Corporate/BusinessReorg/BusinessReorg";
import CorporateDocs from "../components/pages/Corporate/CorporateDocs/CorporateDocs";
import Consult from "../components/pages/Consult/Consult";
import Info from "../components/Info/Info";

const CorporatePage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Info mode="corporate" />
      <BusinessReg />
      <BusinessReorg />
      <CorporateDocs />
      <Consult />
    </Layout>
  );
};

export default CorporatePage;
