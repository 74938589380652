import React from "react";

import Layout from "../components/Layout/Layout";
import Policy from "../components/pages/Policy/Policy";

const PolicyPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Policy />
    </Layout>
  );
};

export default PolicyPage;
