import React from "react";

import styles from "./ServicesMobile.module.scss";

import { Link } from "react-router-dom";

const ServicesMobile: React.FC = () => {
  return (
    <div className={styles.mobileContent}>
      <h2 className={styles.title}>Поддержка малого бизнеса в России</h2>
      <div className={styles.body}>
        <Link to="/corporate" className={styles.item}>
          <div className={styles.image}>
            <img src="/images/main/services/corporate.jpg" alt="Корпоративные услуги" />
          </div>
          <div className={styles.info}>
            <h2 className={styles.label}>Корпоративные услуги</h2>
            <ul className={styles.list}>
              <li className={styles.link}>Регистрация бизнеса</li>
              <li className={styles.link}>Реорганизация бизнеса</li>
              <li className={styles.link}>Разработка кооперативных документов</li>
            </ul>
          </div>
        </Link>
        <Link to="/legal" className={styles.item}>
          <div className={styles.image}>
            <img src="/images/main/services/legal.jpg" alt="Юридические услуги" />
          </div>
          <div className={styles.info}>
            <h2 className={styles.label}>Юридические услуги</h2>
            <ul className={styles.list}>
              <li className={styles.link}>Комплексное обслуживание</li> <br />
              <li className={styles.link}>Договорное право</li>
              <li className={styles.link}>Трудовое право</li>
            </ul>
          </div>
        </Link>
        <Link to="/accounting" className={styles.item}>
          <div className={styles.image}>
            <img src="/images/main/services/accounting.jpg" alt="Бухгалтерские услуги" />
          </div>
          <div className={styles.info}>
            <h2 className={styles.label}>Бухгалтерские услуги</h2>
            <ul className={styles.list}>
              <li className={styles.link}>Комплексное обслуживание</li>
              <li className={styles.link}>Ведение отдельных участков учета</li>
            </ul>
          </div>
        </Link>
        <Link to="/hr" className={styles.item}>
          <div className={styles.image}>
            <img src="/images/main/services/hr.jpg" alt="Кадровые услуги" />
          </div>
          <div className={styles.info}>
            <h2 className={styles.label}>Кадровые услуги</h2>
            <ul className={styles.list}>
              <li className={styles.link}>Воинский учет</li>
              <li className={styles.link}>Ведение кадрового учета</li>
            </ul>
          </div>
        </Link>
        <div className={styles.item}>
          <div className={styles.image}>
            <img src="/images/main/services/offers.jpg" alt="Участникам «Стартапы России»" />
          </div>
          <div className={styles.info}>
            <h2 className={styles.label}>Победителям Студенческий стартап 5-я волна</h2>
            <p>Только первые 100 победителей получат скидку 100 тысяч на комплексное обслуживание!</p>
            <div className={styles.more}>
              <Link to="/offers">Подробнее</Link>
            </div>
          </div>
        </div>
      </div>
      <Link to="/all" className={styles.all}>
        <p>Все наши услуги</p>
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </Link>
    </div>
  );
};

export default ServicesMobile;
