import React from "react";

import styles from "./CorporateDocs.module.scss";
import { Link } from "react-router-dom";
import Modal from "../../../UI/Modal/Modal";
import { IInclude } from "../../Home/Popular/Desktop/PopularDesktop";

const includeService01 = [
  { id: 0, text: "оценка потребностей заказчика действующему законодательству" },
  { id: 1, text: "подготовка проекта Устава" },
  { id: 2, text: "участие в переговорах при согласовании" },
  { id: 3, text: "подача документов в регистрирующий орган" },
];

// const includeService02 = [
//   { id: 0, text: "оценка возможности упрощенной процедуры" },
//   { id: 1, text: "оценка рисков предъявления требований от контрагентов" },
//   { id: 2, text: "подготовка документов для ФНС" },
//   { id: 3, text: "подача документов на ликвидацию" },
//   { id: 4, text: "получения записи о ликвидации Организации" },
// ];

// const includeService03 = [
//   { id: 0, text: "оценка исходного документа" },
//   { id: 1, text: "оценка фактических обстоятельств" },
//   { id: 2, text: "учет всех требований закона к совершаемым действиям" },
//   { id: 3, text: "подготовка юридически точного документа, без скрытых рисков" },
// ];

// const includeService04 = [
//   { id: 0, text: "оценка исходного документа" },
//   { id: 1, text: "оценка фактических обстоятельств" },
//   { id: 2, text: "учет всех требований закона к совершаемым действиям" },
//   { id: 3, text: "подготовка юридически точного документа, без скрытых рисков" },
// ];

const CorporateDocs: React.FC = () => {
  const [isServicesActive, setIsServicesActive] = React.useState(false);

  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "compare" | "feedback" | null>(null);

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
  };

  return (
    <section className={styles.papers} id="corporateDocs">
      {modalMode === "feedback" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" buyService={true} />
      )}
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
        />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={`${styles.label} sub-title`}>Разработка корпоративных документов</div>
          <div className={`${styles.body} ${isServicesActive ? styles.active : ""}`}>
            <div className={styles.column} tabIndex={1}>
              <div className={styles.item}>
                <h3 className={styles.name}>Подготовка индивидуального Устава</h3>
                <p className={styles.text}>
                  Устав – один из важнейших документов в организации, являющийся основой для всей деятельности на долгие годы.
                </p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Подготовка индивидуального Устава",
                      "За услугу",
                      20000,
                      `Устав – один из важнейших документов в организации, являющийся основой для всей деятельности на долгие годы. Правильно подготовленный Устав поможет вам не только достоверно оценивать перспективу деятельности, но и избежать изматывающих споров, в случае возникновения конфликта. 
										`,
                      includeService01,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 20 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.dropdowns} ${isServicesActive ? styles.active : ""}`}>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Подготовка индивидуального Устава",
                    "За услугу",
                    20000,
                    `Устав – один из важнейших документов в организации, являющийся основой для всей деятельности на долгие годы. Правильно подготовленный Устав поможет вам не только достоверно оценивать перспективу деятельности, но и избежать изматывающих споров, в случае возникновения конфликта. 
									`,
                    includeService01,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Подготовка индивидуального Устава</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
          <div
            className={`${styles.all} ${isServicesActive ? styles.active : ""}`}
            onClick={() => setIsServicesActive(!isServicesActive)}
          >
            {/* <button>
              {isServicesActive ? "Свернуть" : "Показать все услуги"}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10L12 15L17 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CorporateDocs;
