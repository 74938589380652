import React from "react";

import Layout from "../components/Layout/Layout";

import Services from "../components/pages/Home/Services/Services";
import Popular from "../components/pages/Home/Popular/Popular";
import Consult from "../components/pages/Consult/Consult";
import Steps from "../components/pages/Home/Steps/Steps";
import Questions from "../components/pages/Home/Questions/Questions";

const HomePage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Services />
      <Popular />
      <Consult />
      <Steps />
      <Questions />
    </Layout>
  );
};

export default HomePage;
