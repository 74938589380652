import React from "react";

import styles from "./Dropdown.module.scss";

interface IOptions {
  id: number;
  title: string;
}

interface DropdownProps {
  title: string;
  text?: string | React.ReactElement;
  green?: boolean;
  blue?: boolean;
  gray?: boolean;
  white?: boolean;
  modalWhite?: boolean;
  modalTable?: boolean;
  modalSpecial?: boolean;
  options?: IOptions[];
}

const Dropdown: React.FC<DropdownProps> = ({
  title,
  text,
  blue,
  green,
  gray,
  white,
  modalWhite,
  modalTable,
  modalSpecial,
  options,
}) => {
  const [isMenuActive, setIsMenuActive] = React.useState(false);

  return (
    <div
      className={
        blue
          ? `${styles.dropdown} ${styles.blue}`
          : green
          ? `${styles.dropdown} ${styles.green}`
          : gray
          ? `${styles.dropdown} ${styles.gray}`
          : white
          ? `${styles.dropdown} ${styles.white}`
          : modalWhite || modalTable
          ? `${styles.dropdown} ${styles.modalWhite}`
          : modalSpecial
          ? `${styles.dropdown} ${styles.modalSpecial}`
          : `${styles.dropdown} ${styles.regular}`
      }
    >
      <div
        onClick={() => setIsMenuActive(!isMenuActive)}
        className={isMenuActive ? `${styles.dropdownBtn} ${styles.active}` : styles.dropdownBtn}
      >
        <h2>{title}</h2>
        {gray ? (
          <div className={styles.circle}>
            <svg width="10" height="18" viewBox="0 0 10 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.999999 17L9 9L1 1" stroke="currentColor" strokeWidth="2" />
            </svg>
          </div>
        ) : (
          <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.999999 17L9 9L1 1" stroke={green || blue ? "white" : "#222"} strokeWidth="2" />
          </svg>
        )}
      </div>
      <ul className={isMenuActive ? `${styles.dropdownContent} ${styles.active}` : styles.dropdownContent}>
        {options?.length ? (
          options.map((item) => (
            <li key={item.id} className={styles.dropdownItem}>
              - {item.title}
            </li>
          ))
        ) : (
          <li className={styles.dropdownItem}>{text}</li>
        )}
        {modalTable && (
          <div className={styles.modalBody}>
            <div className={styles.modalRow}>
              <div className={styles.left}>Пакет документов по бухгалтерскому и налоговому учету текущих операций ( 5 шт. )</div>
              <div className={styles.right}>1 500 ₽ / пакет</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>
                Дополнительный расчетный счет Проведение выписки по 1 расчетному счету ( с учетом предоставления загрузочного
                файла в обязательных форматах )
              </div>
              <div className={styles.right}>1 500 ₽ / месяц</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>
                Подготовка уточненной отчетности ( по причине нарушения Заказчиком сроков предоставления документов )
              </div>
              <div className={styles.right}>3 000 ₽ / месяц</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>Подготовка статистических отчетов в Росстат</div>
              <div className={styles.right}>3 000 ₽ / месяц</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>
                Подготовка уточненной отчетности ( по причине нарушения Заказчиком сроков предоставления документов )
              </div>
              <div className={styles.right}>3 000 ₽ / месяц</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>Пересчет заработной платы ( по запросу Заказчика )</div>
              <div className={styles.right}>2 000 ₽ / сотрудник</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>Плата за срочность оформления документов ( оформление день в день )</div>
              <div className={styles.right}>500 ₽ / документ</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>Выгрузка информационной базы 1С по запросу Заказчика</div>
              <div className={styles.right}>2 500 ₽ / база</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>Выгрузка информационной базы 1С по запросу Заказчика</div>
              <div className={styles.right}>2 500 ₽ / база</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>
                Письменные консультации эксперта по бухгалтерскому, налоговому и кадровому учету в объёме не более 1 часа
              </div>
              <div className={styles.right}>2 500 ₽ / час</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>
                Правовое сопровождение ликвидации ООО по упрощенной процедуре согласно ст. 21.3 ФЗ от 08.08.2001 N 129-ФЗ. В
                услугу включена проверка наличия оснований процедуры, сбор сведений и документов, подготовка заявления в ФНС
              </div>
              <div className={styles.right}>10 000 ₽</div>
            </div>
            <div className={styles.modalRow}>
              <div className={styles.left}>
                Бухгалтерское сопровождение ликвидации ООО (в обычном порядке). <br /> <br /> В услугу включены услуги по
                подготовке отчетности: <br />
                <span>
                  - Отчет «Персонифицированные сведения о физ. лицах» <br /> - ЕФС-1 (разделы СЗВ-ТД, СЗВ-СТАЖ, 4-ФСС) <br /> -
                  Расчет страховых взносов <br /> - Отчет 6-НДФЛ с приложением 2 <br /> - Декларации по НДС и налогу на
                  прибыль/УСН <br /> - Промежуточный бухгалтерский баланс <br /> - Ликвидационный бухгалтерский баланс <br /> -
                  Отчетность по статистическим формам <br /> - Загрузка информационной базы 1С из файла заказчика <br /> <br />
                </span>
              </div>
              <div className={styles.right}>45 000 ₽</div>
            </div>
          </div>
        )}
      </ul>
    </div>
  );
};

export default Dropdown;
