import React from "react";

import styles from "./ContractLaw.module.scss";
import { Link } from "react-router-dom";
import { IInclude } from "../../Home/Popular/Desktop/PopularDesktop";
import Modal from "../../../UI/Modal/Modal";

const includeService01 = [
  { id: 0, text: "бесплатная первичная консультация по всем заявленным критериям к договору" },
  { id: 1, text: "разработка проекта договора с учетом коммерческих условий, необходимых Клиенту" },
  { id: 2, text: "составление необходимых юридических приложений к Договору" },
  { id: 3, text: "участие в согласовании положений договора с контрагентами Клиента, ведение переговоров" },
  { id: 4, text: "анализ налоговых и правовых последствий положений, включаемых в договор" },
  { id: 5, text: "проверка предоставленных контрагентом документов и сведений" },
];

const includeService02 = [
  { id: 0, text: "оценка сведений о контрагенте по открытиям источникам" },
  { id: 1, text: "выявление ошибок юридического характера, некорректных данных" },
  { id: 2, text: "определение условий, не позволяющих считать договор заключенным" },
  { id: 3, text: "выявление скрытых условий" },
  { id: 4, text: "предложение альтернативных вариантов заключения договора, в том числе путем изменение его формы" },
];

const includeService03 = [
  { id: 0, text: "оценка исходного документа" },
  { id: 1, text: "оценка фактических обстоятельств" },
  { id: 2, text: "учет всех требований закона к совершаемым действиям" },
  { id: 3, text: "подготовка юридически точного документа, без скрытых рисков" },
];

const includeService04 = [
  { id: 0, text: "подготовка к проведению переговоров" },
  { id: 1, text: "рассмотрение предложений Вашего контрагента" },
  { id: 2, text: "формулирование вопросов и их редактура" },
  { id: 3, text: "обсуждение предложений и последствий различных вариантов" },
];

const ContractLaw: React.FC = () => {
  const [isServicesActive, setIsServicesActive] = React.useState(false);

  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "services" | "feedback" | null>(null);

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
  };

  return (
    <section className={styles.papers} id="contract">
      {modalMode === "feedback" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" buyService={true} />
      )}
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
        />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={`${styles.label} sub-title`}>Договорное право</div>
          <div className={`${styles.body} ${isServicesActive ? styles.active : ""}`}>
            <div className={styles.column} tabIndex={0}>
              <div className={styles.item}>
                <h3 className={styles.name}>Подготовка договора</h3>
                <p className={styles.text}>Индивидуальная разработка договора юристом</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Подготовка договора",
                      "На один месяц",
                      5000,
                      `Правильно подготовленный договор – основа безопасности для любого бизнеса. Юрист по договорной работе оценит ваш запрос, направит уточняющие вопросы и даст разъяснения о необходимости наличия, либо же исключения, положений из договора. Договор, разработанный индивидуально для вас будет являться вашей собственностью, что гарантирует отсутствие претензий от лиц, размещающих шаблоны договора в открытом доступе. 
										`,
                      includeService01,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div
                  className={styles.buy}
                  onClick={() => {
                    setModalMode("feedback");
                    setIsModalActive(true);
                  }}
                >
                  <p>от 5 000 ₽</p>
                  <div className={styles.button}>
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={1}>
              <div className={styles.item}>
                <h3 className={styles.name}>Экспертиза договора</h3>
                <p className={styles.text}>Комплексный анализ текста договора на наличие скрытых условий и положений</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Экспертиза договора",
                      "За услугу",
                      5000,
                      `Комплексный анализ текста договора на наличие скрытых условий и положений, не позволяющих считать его залеченным даже после подписания – одна из основных задач юриста по договорной работе. Профессиональная оценка поможет вам избавиться от недобросовестных контрагентов и заранее понимать все права и обязанности на период реализации сделки. 
										`,
                      includeService02,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 5 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={2}>
              <div className={styles.item}>
                <h3 className={styles.name}>Подготовка доп. документов к договору</h3>
                <p className={styles.text}>
                  Работа с обстоятельствами не предусмотренными сторонами в момент подписания договора
                </p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Подготовка доп. документов к договору",
                      "На один месяц",
                      1500,
                      `В процессе осуществления договора могут возникнуть обстоятельства не предусмотренные сторонами в момент подписания, на возможности разрешения которых у сторон может быть схожая позиция, либо же наоборот – противоположная. Оформить выход из договора, либо подготовить документ, закрепляющий согласие сторон по разрешению вопроса поможет профессиональный юрист по договорной работе. 
										`,
                      includeService03,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 5 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={3}>
              <div className={styles.item}>
                <h3 className={styles.name}>Участие в переговорах</h3>
                <p className={styles.text}>Подберем правильные вопросы, моментально выявим противоречащие закону условия</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Участие в переговорах",
                      "На один месяц",
                      7500,
                      `Сложные, затяжные, без ясного понимания запросов контрагентов – все это могут быть переговоры. Подобрать правильные вопросы, а также моментально выявить противоречащие закону условия, предлагаемые контрагентом, вам поможет юрист по договорному праву.
										`,
                      includeService04,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 5 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.dropdowns} ${isServicesActive ? styles.active : ""}`}>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Подготовка договора",
                    "На один месяц",
                    5000,
                    `Правильно подготовленный договор – основа безопасности для любого бизнеса. Юрист по договорной работе оценит ваш запрос, направит уточняющие вопросы и даст разъяснения о необходимости наличия, либо же исключения, положений из договора. Договор, разработанный индивидуально для вас будет являться вашей собственностью, что гарантирует отсутствие претензий от лиц, размещающих шаблоны договора в открытом доступе. 
									`,
                    includeService01,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Подготовка договора</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Экспертиза договора",
                    "За услугу",
                    5000,
                    `Комплексный анализ текста договора на наличие скрытых условий и положений, не позволяющих считать его залеченным даже после подписания – одна из основных задач юриста по договорной работе. Профессиональная оценка поможет вам избавиться от недобросовестных контрагентов и заранее понимать все права и обязанности на период реализации сделки. 
									`,
                    includeService02,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Экспертиза договора</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Подготовка доп. документов к договору",
                    "На один месяц",
                    1500,
                    `В процессе осуществления договора могут возникнуть обстоятельства не предусмотренные сторонами в момент подписания, на возможности разрешения которых у сторон может быть схожая позиция, либо же наоборот – противоположная. Оформить выход из договора, либо подготовить документ, закрепляющий согласие сторон по разрешению вопроса поможет профессиональный юрист по договорной работе. 
									`,
                    includeService03,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Подготовка доп. документов к договору</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Участие в переговорах",
                    "На один месяц",
                    7500,
                    `Сложные, затяжные, без ясного понимания запросов контрагентов – все это могут быть переговоры. Подобрать правильные вопросы, а также моментально выявить противоречащие закону условия, предлагаемые контрагентом, вам поможет юрист по договорному праву.
									`,
                    includeService04,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Участие в переговорах</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
          <div
            className={`${styles.all} ${isServicesActive ? styles.active : ""}`}
            onClick={() => setIsServicesActive(!isServicesActive)}
          >
            <button>
              {isServicesActive ? "Свернуть" : "Показать все услуги"}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10L12 15L17 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContractLaw;
