import React from "react";

import styles from "./Desktop/PopularDesktop.module.scss";

import PopularDesktop from "./Desktop/PopularDesktop";
import PopularTablet from "./Tablet/PopularTablet";
import PopularMobile from "./Mobile/PopularMobile";

const Popular: React.FC = () => {
  return (
    <section className={styles.popular}>
      <div className="container">
        <PopularDesktop />
        <PopularTablet />
        <PopularMobile />
      </div>
    </section>
  );
};

export default Popular;
