import React from "react";

import styles from "./PopularDesktop.module.scss";

import { Link } from "react-router-dom";
import Modal from "../../../../UI/Modal/Modal";

export interface IInclude {
  id: number;
  text: string;
}

const includeService01 = [
  { id: 0, text: "подготовка заявления на регистрацию в онлайн формате" },
  { id: 1, text: "проверка комплектности пакета документов" },
  { id: 2, text: "помощь с подбором ОКВЭД в зависимости от предполагаемой деятельности" },
  { id: 3, text: "помощь с подбором оптимального режима налогообложения" },
];

const includeService02 = [
  { id: 0, text: "бесплатная первичная консультация по всем заявленным критериям к договору" },
  { id: 1, text: "разработка проекта договора с учетом коммерческих условий, необходимых Клиенту" },
  { id: 2, text: "составление необходимых юридических приложений к Договору" },
  { id: 3, text: "участие в согласовании положений договора с контрагентами Клиента, ведение переговоров" },
  { id: 4, text: "анализ налоговых и правовых последствий положений, включаемых в договор" },
  { id: 5, text: "проверка предоставленных контрагентом документов и сведений" },
];

const includeService03 = [
  { id: 0, text: "подготовка формы Р11001" },
  { id: 1, text: "помощь с подбором оптимального типового устава" },
  { id: 2, text: "подготовка решения учредителя" },
  { id: 3, text: "помощь с подбором ОКВЭД в зависимости от предполагаемой деятельности" },
  { id: 4, text: "помощь с подбором оптимального режима налогообложения" },
  { id: 5, text: "заполнение формы перехода не иной режим налогообложения" },
  { id: 6, text: "подача документов в формате онлайн (*при наличии ЭЦП)" },
];

const includeService04 = [
  { id: 0, text: "разработка проекта трудового договора и внесение правок после согласования" },
  { id: 1, text: "рекомендации по определению трудовой функции в соответствии с ЕКСД" },
  { id: 2, text: "оценим возможности трудоустройства сотрудника по совместительству" },
  { id: 3, text: "поможем с заключением договора в формате «онлайн». " },
];

const includeService05 = [
  { id: 0, text: "оценка возможности упрощенной процедуры" },
  { id: 1, text: "оценка рисков предъявления требований от контрагентов" },
  { id: 2, text: "подготовка документов для ФНС" },
  { id: 3, text: "подача документов на ликвидацию" },
  { id: 4, text: "получения записи о ликвидации Организации" },
];

const includeService06 = [
  { id: 0, text: "сверка с ФНС" },
  { id: 1, text: "подготовка полного пакета документов" },
  { id: 4, text: "дистанционная подача (* при наличии действующего ЭЦП)" },
];

const includeService07 = [
  { id: 0, text: "оценка сведений о контрагенте по открытиям источникам" },
  { id: 1, text: "выявление ошибок юридического характера, некорректных данных" },
  { id: 2, text: "определение условий, не позволяющих считать договор заключенным" },
  { id: 3, text: "выявление скрытых условий" },
  { id: 4, text: "предложение альтернативных вариантов заключения договора, в том числе путем изменение его формы" },
];

const includeService08 = [
  { id: 0, text: "сдача «нулевой» отчетности" },
  { id: 1, text: "подготовка отчетности в СФР, ФНС, Росстат в формате ЭДО" },
  { id: 2, text: "предварительная проверка отчетности на наличие ошибок по алгоритмам государственных органов" },
  { id: 3, text: "предоставление календаря с перечнем сдаваемых отчетов" },
  { id: 4, text: "получение моментального уведомления от государственных органов о принятом отчете" },
  { id: 5, text: "бесплатное исправление ошибок в случае отказа государственного органа" },
];

const PopularDesktop: React.FC = () => {
  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "compare" | "feedback" | null>(null);
  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
  };

  return (
    <>
      {modalMode === "feedback" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" buyService={true} />
      )}
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
        />
      )}
      <div className={styles.popularDesktop}>
        <h2 className={`${styles.label} title`}>
          Услуги, которыми <br /> интересуются чаще остальных
        </h2>
        <div className={styles.body}>
          <div
            className={styles.column}
            onClick={() => {
              handleOpenModal(
                "Регистрация ИП",
                "Выполним за одну неделю",
                4500,
                `Поможем зарегистрировать ИП в любой точке России быстро и безошибочно. Берем на себя полный цикл: от выбора системы налогообложения и подготовки документов до помощи в открытии счета на оптимальных условиях.
							`,
                includeService01,
                false,
                ""
              );
              setModalMode("more");
            }}
          >
            <div className={`${styles.tallLine} ${styles.first}`} />
            <div className={styles.item}>
              <div className={styles.info}>
                <div className={styles.icon}>
                  <img width={36} src="/images/main/popular/registration.svg" alt="Регистрация ИП" />
                </div>
                <h3>Регистрация ИП</h3>
                <p>
                  Обеспечиваем полный цикл <br /> регистрации статуса <br /> индивидуальный предприниматель
                </p>
              </div>
            </div>
          </div>
          <div
            className={styles.column}
            onClick={() => {
              handleOpenModal(
                "Подготовка договора",
                "Выполним за одну неделю",
                5000,
                `Правильно подготовленный договор – основа безопасности для любого бизнеса.
							Юрист по договорной работе оценит ваш запрос, направит уточняющие вопросы и даст разъяснения о необходимости наличия, либо же исключения, положений из договора. 
							Договор, разработанный индивидуально для вас будет являться вашей собственностью, что гарантирует отсутствие претензий от лиц, размещающих шаблоны договора в открытом доступе. 
							`,
                includeService02,
                false,
                ""
              );
              setModalMode("more");
            }}
          >
            <div className={`${styles.tallLine} ${styles.second}`} />
            <div className={styles.item}>
              <div className={styles.info}>
                <div className={styles.icon}>
                  <img width={36} src="/images/main/popular/registration.svg" alt="Регистрация ИП" />
                </div>
                <h3>Подготовка договора</h3>
                <p>
                  Обеспечиваем полный цикл <br /> регистрации статуса <br /> индивидуальный предприниматель
                </p>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.info}>
                <div className={styles.image}>
                  <img src="/images/main/popular/laptop.jpg" alt="Ноутбук" />
                </div>
              </div>
            </div>
          </div>
          <hr className={styles.longLine} />
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.info}>
                <h3>Регистрация ООО</h3>
                <p>
                  Открытие юридического лица в <br /> формате общества с <br /> ограниченной ответственностью...
                </p>
                <div className={styles.more}>
                  <Link
                    to=""
                    onClick={() => {
                      handleOpenModal(
                        "Регистрация ООО",
                        "Выполним за одну неделю",
                        7500,
                        `Открытие юридического лица в формате общества с ограниченной ответственностью – один из наиболее популярных в РФ способов ведения бизнеса. Мы поможем с открытием Общества под ключ и с гарантией.  
							`,
                        includeService03,
                        true,
                        ""
                      );
                      setModalMode("more");
                    }}
                  >
                    Подробнее
                  </Link>
                </div>
                <div className={styles.price}>
                  <p>от 7 500 ₽</p>
                  <Link
                    to=""
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    Купить
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.info}>
                <h3>Закрытие ИП</h3>
                <p>
                  Планируете прекратить свою деятельность? Сделать это <br /> легально можно только одним п...
                </p>
                <div className={styles.more}>
                  <Link
                    to=""
                    onClick={() => {
                      handleOpenModal(
                        "Закрытие ИП",
                        "Выполним за одну неделю",
                        9000,
                        `Планируете прекратить свою деятельность? Сделать это легально можно только одним путем – закрыть ИП. Поможем со всем процедурой быстро и с гарантией результата.   
							`,
                        includeService06,
                        true,
                        ""
                      );
                      setModalMode("more");
                    }}
                  >
                    Подробнее
                  </Link>
                </div>
                <div className={styles.price}>
                  <p>от 9 000 ₽</p>
                  <Link
                    to=""
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    Купить
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.info}>
                <h3>Разработка трудового договора</h3>
                <p>
                  Индивидуальная разработка <br /> трудового договора для Вашей <br /> компании
                </p>
                <div className={styles.more}>
                  <Link
                    to=""
                    onClick={() => {
                      handleOpenModal(
                        "Разработка трудового договора",
                        "Выполним за одну неделю",
                        5000,
                        `Заключение трудового договора между работником и работодателем - важный этап законной работы Вашей компании. В этом документе закрепляются права и обязанности сторон трудовых отношений. Трудовой договор - гарант как для Вас, так и для Вашего сотрудника в части добросовестного соблюдения прав и исполнения обязанностей.  
								`,
                        includeService04,
                        false,
                        ""
                      );
                      setModalMode("more");
                    }}
                  >
                    Подробнее
                  </Link>
                </div>
                <div className={styles.price}>
                  <p>от 5 000 ₽</p>
                  <Link
                    to=""
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    Купить
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <hr className={styles.longLine} />
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.info}>
                <h3>Ликвидация ООО</h3>
                <p>
                  Поможем с полной и упрощенной <br /> процедурой прекращения <br /> деятельности Организации с гар...
                </p>
                <div className={styles.more}>
                  <Link
                    to=""
                    onClick={() => {
                      handleOpenModal(
                        "Ликвидация ООО",
                        "Выполним за одну неделю",
                        15000,
                        `Поможем с полной и упрощенной процедурой прекращения деятельности Организации с гарантией результата и без задержек по времени.   
								`,
                        includeService05,
                        false,
                        ""
                      );
                      setModalMode("more");
                    }}
                  >
                    Подробнее
                  </Link>
                </div>
                <div className={styles.price}>
                  <p>от 15 000 ₽</p>
                  <Link
                    to=""
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    Купить
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.info}>
                <h3>Экспертиза договора</h3>
                <p>
                  Комплексный анализ текста <br /> договора на наличие скрытых <br /> условий и положений
                </p>
                <div className={styles.more}>
                  <Link
                    to=""
                    onClick={() => {
                      handleOpenModal(
                        "Экспертиза договора",
                        "За услугу",
                        5000,
                        `Комплексный анализ текста договора на наличие скрытых условий и положений, не позволяющих считать его залеченным даже после подписания – одна из основных задач юриста по договорной работе. Профессиональная оценка поможет вам избавиться от недобросовестных контрагентов и заранее понимать все права и обязанности на период реализации сделки. 
								`,
                        includeService07,
                        false,
                        ""
                      );
                      setModalMode("more");
                    }}
                  >
                    Подробнее
                  </Link>
                </div>
                <div className={styles.price}>
                  <p>от 5 000 ₽</p>
                  <Link
                    to=""
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    Купить
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.info}>
                <h3>
                  Подготовка и сдача <br /> бухгалтерской и <br /> налоговой отчетности
                </h3>
                <p>
                  Бухгалтерская отчётность <br /> содержит обобщённые сведения о <br /> финансовом и имущественном п...
                </p>
                <div className={styles.more}>
                  <Link
                    to=""
                    onClick={() => {
                      handleOpenModal(
                        "Подготовка и сдача бухгалтерской и налоговой отчетности",
                        "Выполним за одну неделю",
                        15000,
                        `Бухгалтерская отчётность содержит обобщённые сведения о финансовом и имущественном положении организации и о результатах её деятельности, включая информацию о работе филиалов и обособленных подразделений и требует учета множества нюансов, при текущем быстром изменении законодательства. Правильно подготовленный и отправленный отчет поможет вам избежать штрафов от государственных органов и потери времени на составление объяснительных и походов на очные ставки.    
								`,
                        includeService08,
                        false,
                        "Стоимость рассчитывается в индивидуальном порядкe"
                      );
                      setModalMode("more");
                    }}
                  >
                    Подробнее
                  </Link>
                </div>
                <div className={styles.price}>
                  <p>от 5 000 ₽</p>
                  <Link
                    to=""
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    Купить
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* TEMPORARY <div className={styles.allServices}>
          <button>
            <Link to="">Все наши услуги</Link>
          </button>
          <img src="/images/general/arrow.svg" alt="arrow" />{" "}
        </div> */}
      </div>
    </>
  );
};

export default PopularDesktop;
