import React from "react";

import styles from "./Consult.module.scss";
import { Link } from "react-router-dom";
import Modal from "../../UI/Modal/Modal";

const Consult: React.FC = () => {
  const [isModalActive, setIsModalActive] = React.useState(false);

  React.useEffect(() => {
    if (isModalActive) {
      document.body.classList.add("lock");
    } else {
      document.body.classList.remove("lock");
    }

    return () => {
      document.body.classList.remove("lock");
    };
  }, [isModalActive]);

  return (
    <section className={styles.consult}>
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.left}>
            <h2 className={styles.title}>Не знаете что выбрать?</h2>
            <p className={styles.text}>
              Оставьте вашу заявку и наши специалисты <br /> свяжутся с вами в ближайшее время
            </p>
            <Link onClick={() => setIsModalActive(true)} to="" className={styles.button}>
              <button>Получить консультацию</button>
            </Link>
          </div>
          <div className={styles.right}>
            <div className={styles.image}>
              <img src="/images/main/consult/feedback.svg" alt="Обратная связь" />
            </div>
          </div>
        </div>
      </div>
      <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" />
    </section>
  );
};

export default Consult;
