import React from "react";

import styles from "./ServicesTablet.module.scss";

import { useMedia } from "../../../../../hooks/useMedia";
import { Link } from "react-router-dom";

const ServicesTablet: React.FC = () => {
  const isSmallScreen = useMedia("(max-width: 901px) and (min-width: 622px)");

  const [activeIndex, setActiveIndex] = React.useState(0);

  React.useEffect(() => {
    if (isSmallScreen) {
      const timer = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
      }, 5000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isSmallScreen]);

  return (
    <div className={styles.tabletContent}>
      <div className={styles.left}>
        <h2 className={styles.title}>Поддержка малого бизнеса в России</h2>
        <Link to="/corporate" className={`${styles.item} ${styles.corporate} ${activeIndex === 0 ? styles.active : ""}`}>
          <h2 className={styles.label}>Корпоративные услуги</h2>
          <ul className={styles.list}>
            <li className={styles.link}>Регистрация бизнеса</li>
            <li className={styles.link}>Реорганизация бизнеса</li>
            <li className={styles.link}>Разработка кооперативных документов</li>
          </ul>
        </Link>
        <Link to="/accounting" className={`${styles.item} ${styles.accounting} ${activeIndex === 1 ? styles.active : ""}`}>
          <h2 className={styles.label}>Бухгалтерские услуги</h2>
          <ul className={styles.list}>
            <li className={styles.link}>Комплексное обслуживание</li>
            <li className={styles.link}>Ведение отдельных участков учета</li>
          </ul>
        </Link>
        <Link to="/legal" className={`${styles.item} ${styles.legal} ${activeIndex === 2 ? styles.active : ""}`}>
          <h2 className={styles.label}>Юридические услуги</h2>
          <ul className={styles.list}>
            <li className={styles.link}>Комплексное обслуживание</li> <br />
            <li className={styles.link}>Договорное право</li>
            <li className={styles.link}>Трудовое право</li>
          </ul>
        </Link>
      </div>
      <div className={styles.tabletDivider} />
      <div className={styles.right}>
        <div className={`${styles.item} ${styles.members}`}>
          <div className={styles.image}>
            <img src="/images/main/services/offers.jpg" alt="Участникам «Стартапы России»" />
          </div>
          <div className={styles.label}>
            <h2>Победителям Студенческий стартап 5-я волна</h2>
            <p>Только первые 100 победителей получат скидку 100 тысяч на комплексное обслуживание!</p>
            <div className={styles.more}>
              <Link to="/offers">Подробнее</Link>
            </div>
          </div>
        </div>
        <Link to="/hr" className={`${styles.item} ${styles.hr} ${activeIndex === 3 ? styles.active : ""}`}>
          <h2 className={styles.label}>Кадровые услуги</h2>
          <ul className={styles.list}>
            <li className={styles.link}>Воинский учет</li>
            <li className={styles.link}>Ведение кадрового учета</li>
          </ul>
        </Link>
        <Link to="/all" className={`${styles.item} ${styles.all}`}>
          <h2 className={styles.label}>
            Все наши услуги
            <img src="/images/general/arrow.svg" alt="arrow" />
          </h2>
        </Link>
      </div>
    </div>
  );
};

export default ServicesTablet;
