import React from "react";

import styles from "./Questions.module.scss";
import Dropdown from "../../../UI/Dropdown/Dropdown";
import { Link } from "react-router-dom";

const DEFAULT_QUESTION = {
  user: "",
  medias: "",
  problem: "",
};

const Questions: React.FC = () => {
  const [question, setQuestion] = React.useState(DEFAULT_QUESTION);
  const [message, setMessage] = React.useState("");
  const [isFormSended, setIsFormSended] = React.useState(false);

  const isAllFieldsEmpty = question.user.length > 0 && question.medias.length > 0 && question.problem.length > 0;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch("https://lexlegal.ru/api/MainPage/feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: question.user,
          medias: question.medias,
          problem: question.problem ? question.problem : "Не определился",
          problemTheme: "Форма отправлена с главной страницы. Нужна консультация.",
        }),
      });

      if (response.ok) {
        setIsFormSended(true);
        setQuestion(DEFAULT_QUESTION);
        setMessage("");
      } else {
        setMessage("Произошла ошибка при отправке вашей заявки");
      }
    } catch (error) {
      setMessage("Не удалось отправить заявку, попробуйте еще раз");
    }
  };

  return (
    <section className={styles.questions}>
      <div className="container">
        <h2 className={`${styles.label} title`}>Часто задавыемые вопросы</h2>
        <div className={styles.body}>
          <div className={styles.left}>
            <div className={styles.item}>
              <Dropdown
                title="1.	Нужно ли мне оплачивать услуги до подписания договора?"
                text="Ответ: Нет, все услуги оплачиваются после ознакомления с договором и его подписания. Более того, у нас предусмотрена возможность разбить платежи на весь период договора, либо сделать отсрочку основного платежа. Подробности уточняйте у специалиста."
                gray={true}
              />
            </div>
            <div className={styles.item}>
              <Dropdown
                title="2.	Почему цена на услуги ниже средней по рынку?"
                text="Ответ: Два фактора существенно влияют на конечную цену для покупателя: во-первых, все наши сотрудники работают дистанционно из разных точек по всему миру; во-вторых, автоматизации, используемые в рабочих процессах, позволяют убрать из цепочки между клиентом и исполнителей лишний людей."
                gray={true}
              />
            </div>
            <div className={styles.item}>
              <Dropdown
                title="3.	Как можно понять, что ваши услуги качественные?"
                text="Ответ: Специалисты, которые будут закреплены за сопровождением вашего проекта, подбираются профильно из штатных сотрудников, а следовательно их компетенции и интересы будут соответствовать тематики вашего проекта, что не только упрощает коммуникацию, но и создает максимальный уровень качества для оказываемых услуг."
                gray={true}
              />
            </div>
            <div className={styles.item}>
              <Dropdown
                title="4.	Кто будет заниматься моим проектом при комплексном обслуживании?"
                text="Ответ: Отработанная годами стратегия подключения сразу 2-х профильных специалистов к работе над проектом зарекомендовала себя наилучшим образом, именно поэтому с первого дня обслуживания главный бухгалтер и юрист в сфере Legal tech примут ваш проект под свой контроль."
                gray={true}
              />
            </div>
            <div className={styles.item}>
              <Dropdown
                title="5.	Как вы будете сдавать мою отчетность по бухгалтерии либо присылать договоры?"
                text="Ответ: Наша работа организована полностью в формате online! Каждый из специалистов отлично владеет различными техническими инструментами, такими как Discord, AnyDesk, Microsoft Outlook, а также различные узкопрофильные сервисы для подключения и настройки ЭДО, дистанционной сдачи налоговой отчетности и т.д. Для работы с нами вам будет достаточно компьютера! "
                gray={true}
              />
            </div>
            <div className={styles.item}>
              <Dropdown
                title="6.  Где можно узнать опыт работы с вами от реальных клиентов?"
                text={
                  <>
                    Ответ: Многие клиенты любезно после первого года совместной работы предоставляют нам рекомендательные письма.
                    Это документы официального образца с подписью и печатью генерального директора, поэтому подделать их
                    невозможно. В таких письмах имеются контактные данные фирмы получившей наши услуги – почта, телефон или сайт.
                    Такие письма вы можете найти в нашем сообществе <a href="https://vk.com">ВК</a>.
                  </>
                }
                gray={true}
              />
            </div>
          </div>
          <div className={styles.right}>
            {isFormSended ? (
              <div className={styles.sended}>
                <div className={styles.icon}>
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M24 0C30.3652 0 36.4697 2.52856 40.9706 7.02944C45.4714 11.5303 48 17.6348 48 24C48 30.3652 45.4714 36.4697 40.9706 40.9706C36.4697 45.4714 30.3652 48 24 48C17.6348 48 11.5303 45.4714 7.02944 40.9706C2.52856 36.4697 0 30.3652 0 24C0 17.6348 2.52856 11.5303 7.02944 7.02944C11.5303 2.52856 17.6348 0 24 0ZM21.0103 28.7349L15.6789 23.4C15.4877 23.2089 15.2608 23.0573 15.0111 22.9538C14.7614 22.8504 14.4937 22.7971 14.2234 22.7971C13.9531 22.7971 13.6855 22.8504 13.4358 22.9538C13.186 23.0573 12.9591 23.2089 12.768 23.4C12.382 23.786 12.1651 24.3095 12.1651 24.8554C12.1651 25.4013 12.382 25.9249 12.768 26.3109L19.5566 33.0994C19.7472 33.2915 19.9739 33.444 20.2237 33.548C20.4735 33.6521 20.7414 33.7057 21.012 33.7057C21.2826 33.7057 21.5505 33.6521 21.8003 33.548C22.0501 33.444 22.2768 33.2915 22.4674 33.0994L36.5246 19.0389C36.7183 18.8485 36.8723 18.6217 36.9779 18.3715C37.0835 18.1214 37.1385 17.8527 37.1398 17.5812C37.1411 17.3096 37.0886 17.0405 36.9853 16.7894C36.882 16.5382 36.7301 16.31 36.5382 16.1179C36.3463 15.9257 36.1182 15.7735 35.8672 15.6699C35.6161 15.5664 35.3471 15.5135 35.0755 15.5145C34.804 15.5154 34.5353 15.5701 34.285 15.6754C34.0347 15.7807 33.8077 15.9345 33.6171 16.128L21.0103 28.7349Z"
                      fill="#34C759"
                    />
                  </svg>
                </div>
                <h2>Ваша заявка была успешно отправлена</h2>
                <p>Наша команда специалистов свяжется с вами в ближайшее время</p>
                <button onClick={() => setIsFormSended(false)}>Отправить еще 1 заявку</button>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className={styles.form}>
                <h2>
                  Предлагаем обсудить <br /> вашу ситуацию
                </h2>
                <div className={styles.text}>
                  <p>Оставьте вашу заявку и наши специалисты свяжутся с вами в ближайшее время</p>
                </div>
                <div className={styles.field}>
                  <input
                    value={question.user}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion({ ...question, user: e.target.value })}
                    type="text"
                    placeholder="Как к вам обращаться"
                    required
                  />
                </div>
                <div className={message.length === 0 ? `${styles.field}` : `${styles.field} ${styles.incorrect}`}>
                  <input
                    value={question.medias}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion({ ...question, medias: e.target.value })}
                    type="text"
                    placeholder="Ваш Telegram/ ВКонтакте / моб. телефон"
                    required
                  />
                  <p>{message}</p>
                </div>
                <div className={styles.field}>
                  <textarea
                    value={question.problem}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setQuestion({ ...question, problem: e.target.value })
                    }
                    placeholder="Кратко опишите ситуацию"
                    required
                  ></textarea>
                </div>
                <h3>
                  <span>*Нажимая кнопку отправить, вы соглашаетесь с</span>{" "}
                  <Link to="/policy">политикой обработки персональных данных</Link>
                </h3>
                <button className={isAllFieldsEmpty ? `${styles.submit} ${styles.fullfilled}` : styles.submit}>Отправить</button>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questions;
