import React from "react";

import Layout from "../components/Layout/Layout";

import Services from "../components/pages/All/Services/Services";
import Consult from "../components/pages/Consult/Consult";
import Info from "../components/pages/All/Info/Info";

const AllServicesPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Info />
      <Services />
      <Consult />
    </Layout>
  );
};

export default AllServicesPage;
