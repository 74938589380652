import React from "react";

import styles from "./Info.module.scss";
import { Link as ScrollLink } from "react-scroll";

interface InfoProps {
  mode: "corporate" | "legal" | "accounting" | "hr";
}

const Info: React.FC<InfoProps> = ({ mode }) => {
  const [isClicked, setIsClicked] = React.useState({
    first: true,
    second: false,
    third: false,
  });

  return (
    <section className={styles.info}>
      <div className={styles.container}>
        <div
          className={`${styles.body} ${
            mode === "corporate"
              ? styles.corporate
              : mode === "legal"
              ? styles.legal
              : mode === "accounting"
              ? styles.accounting
              : mode === "hr"
              ? styles.hr
              : ""
          }`}
        >
          <div className={styles.left}>
            <h2 className={styles.title}>
              {mode === "corporate"
                ? "Корпоративные услуги"
                : mode === "legal"
                ? "Юридические услуги"
                : mode === "accounting"
                ? "Бухгалтерские услуги"
                : mode === "hr"
                ? "Кадровые услуги"
                : ""}
            </h2>
            <p className={styles.text}>
              {mode === "corporate"
                ? "Наша компания поможет с регистрацией и реорганизацией вашего бизнеса по необходимому запросу"
                : mode === "legal"
                ? "Наша компания осуществляет комплексную юридическую помощь и поддержку для малого бизнеса в России "
                : mode === "accounting"
                ? "Наша компания осуществляет комплексную бухгалтерскую поддержку для малого бизнеса в России"
                : mode === "hr"
                ? "Наша компания поможет с регистрацией и реорганизацией вашего бизнеса по необходимому запросу"
                : ""}
            </p>
            {mode === "corporate" ? (
              <>
                <ScrollLink
                  to="businessReg"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  onMouseEnter={() => setIsClicked({ ...isClicked, first: true, second: false, third: false })}
                  className={isClicked.first ? styles.complex : styles.other}
                >
                  Регистрация бизнеса
                </ScrollLink>
                <ScrollLink
                  to="businessReorg"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  onMouseEnter={() => setIsClicked({ ...isClicked, first: false, second: true, third: false })}
                  className={isClicked.second ? styles.complex : styles.other}
                >
                  Реорганизация бизнеса
                </ScrollLink>
                <br />
                <ScrollLink
                  to="corporateDocs"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  onMouseEnter={() => setIsClicked({ ...isClicked, first: false, second: false, third: true })}
                  className={isClicked.third ? styles.complex : styles.other}
                >
                  Разработка корпоративных документов
                </ScrollLink>
              </>
            ) : mode === "legal" ? (
              <>
                <ScrollLink
                  to="complex"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  onMouseEnter={() => setIsClicked({ ...isClicked, first: true, second: false, third: false })}
                  className={isClicked.first ? styles.complex : styles.other}
                >
                  Комплексное обслуживание
                </ScrollLink>
                <div className={styles.buttons}>
                  <ScrollLink
                    to="contract"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={500}
                    onMouseEnter={() => setIsClicked({ ...isClicked, first: false, second: true, third: false })}
                    className={isClicked.second ? styles.complex : styles.other}
                  >
                    Договорное право
                  </ScrollLink>
                  <ScrollLink
                    to="labor"
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={500}
                    onMouseEnter={() => setIsClicked({ ...isClicked, first: false, second: false, third: true })}
                    className={isClicked.third ? styles.complex : styles.other}
                  >
                    Трудовое право
                  </ScrollLink>
                </div>
              </>
            ) : mode === "accounting" ? (
              <>
                <ScrollLink
                  to="rate"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  onMouseEnter={() => setIsClicked({ ...isClicked, first: true, second: false, third: false })}
                  className={isClicked.first ? styles.complex : styles.other}
                >
                  Комплексное обслуживание
                </ScrollLink>
                <ScrollLink
                  to="papers"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  onMouseEnter={() => setIsClicked({ ...isClicked, first: false, second: true, third: false })}
                  className={isClicked.second ? styles.complex : styles.other}
                >
                  Ведение отдельных участков учета
                </ScrollLink>
              </>
            ) : mode === "hr" ? (
              <>
                <ScrollLink
                  to="military"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  onMouseEnter={() => setIsClicked({ ...isClicked, first: true, second: false, third: false })}
                  className={isClicked.first ? styles.complex : styles.other}
                >
                  Воинский учет
                </ScrollLink>
                <ScrollLink
                  to="hr"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  onMouseEnter={() => setIsClicked({ ...isClicked, first: false, second: true, third: false })}
                  className={isClicked.second ? styles.complex : styles.other}
                >
                  Ведение кадрового учета
                </ScrollLink>
              </>
            ) : (
              ""
            )}
          </div>
          <div className={styles.right}>
            <div className={styles.image}>
              {mode === "corporate" ? (
                <img src="/images/main/info/illustration-corporate.svg" alt="illustration" />
              ) : mode === "legal" ? (
                <img src="/images/main/info/illustration-legal.svg" alt="illustration" />
              ) : mode === "accounting" ? (
                <img src="/images/main/info/illustration-accounting.svg" alt="illustration" />
              ) : mode === "hr" ? (
                <img src="/images/main/info/illustration-hr.svg" alt="illustration" />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
