import React from "react";

import styles from "./Services.module.scss";
import { IService } from "../../../Layout/Header/Header";
import { Link } from "react-router-dom";

const Services: React.FC = () => {
  const [isMenuActive, setIsMenuActive] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState<IService>({
    corporate: false,
    legal: true,
    accounting: false,
    hr: false,
    offers: false,
    contacts: false,
  });
  React.useEffect(() => {
    const main = document.querySelector("main");
    if (isMenuActive) {
      document.body.classList.add("lock");
      main?.classList.add("header-menu-active");
    } else {
      document.body.classList.remove("lock");
      main?.classList.remove("header-menu-active");
    }

    return () => {
      document.body.classList.remove("lock");
      main?.classList.remove("header-menu-active");
    };
  }, [isMenuActive]);

  return (
    <section className={styles.block}>
      <div className="container">
        <div className={styles.row}>
          <div className={styles.buttonsTablet}>
            <button
              onClick={() =>
                setSelectedService({
                  corporate: true,
                  legal: false,
                  accounting: false,
                  hr: false,
                  offers: false,
                  contacts: false,
                })
              }
              className={selectedService.corporate ? `${styles.option} ${styles.active}` : styles.option}
            >
              <img src="/images/header/corporate.svg" alt="Корпоративные услуги" />
              Корпоративные услуги
            </button>
            <button
              onClick={() =>
                setSelectedService({
                  corporate: false,
                  legal: true,
                  accounting: false,
                  hr: false,
                  offers: false,
                  contacts: false,
                })
              }
              className={selectedService.legal ? `${styles.option} ${styles.active}` : styles.option}
            >
              <img src="/images/header/legal.svg" alt="Юридические услуги" />
              Юридические услуги
            </button>
            <button
              onClick={() =>
                setSelectedService({
                  corporate: false,
                  legal: false,
                  accounting: true,
                  hr: false,
                  offers: false,
                  contacts: false,
                })
              }
              className={selectedService.accounting ? `${styles.option} ${styles.active}` : styles.option}
            >
              <img src="/images/header/accounting.svg" alt="Бухгалтерские услуги" />
              Бухгалтерские услуги
            </button>
            <button
              onClick={() =>
                setSelectedService({
                  corporate: false,
                  legal: false,
                  accounting: false,
                  hr: true,
                  offers: false,
                  contacts: false,
                })
              }
              className={selectedService.hr ? `${styles.option} ${styles.active}` : styles.option}
            >
              <img src="/images/header/hr.svg" alt="Кадровые услуги" />
              Кадровые услуги
            </button>
            <button
              onClick={() =>
                setSelectedService({
                  corporate: false,
                  legal: false,
                  accounting: false,
                  hr: false,
                  offers: true,
                  contacts: false,
                })
              }
              className={selectedService.offers ? `${styles.option} ${styles.active}` : styles.option}
            >
              <img src="/images/header/offers.svg" alt="Акционные предложения" />
              Акционные предложения
            </button>
          </div>
          <ul className={styles.services}>
            {selectedService.corporate && (
              <>
                <li className={styles.item}>
                  <Link to="/corporate">Регистрация ИП</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/corporate">Регистрация ООО</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/corporate">Регистрация НКО</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/corporate">Продажа доли в ООО</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/corporate">Ликвидация ИП</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/corporate">Ликвидация ООО</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/corporate">Ликвидация НКО</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/corporate">Подготовка индивидуального Устава</Link>
                </li>
              </>
            )}
            {selectedService.legal && (
              <>
                <div className={styles.divider}>
                  <div className={styles.first}>
                    <li className={styles.item}>
                      <Link to="/legal">Комплексное обслуживание</Link>
                    </li>
                    <li className={styles.item}>
                      <Link to="/legal">Подготовка договора</Link>
                    </li>
                    <li className={styles.item}>
                      <Link to="/legal">Экспертиза договора</Link>
                    </li>
                    <li className={styles.item}>
                      <Link to="/legal">Подготовка доп. документов к договору</Link>
                    </li>
                    <li className={styles.item}>
                      <Link to="/legal">Участие в переговорах</Link>
                    </li>
                    <li className={styles.item}>
                      <Link to="/legal">Претензионная работа</Link>
                    </li>
                    <li className={styles.item}>
                      <Link to="/legal">Проверка закрывающих документов</Link>
                    </li>
                    <li className={styles.item}>
                      <Link to="/legal">Разработка трудового договора</Link>
                    </li>
                    <li className={styles.item}>
                      <Link to="/legal">Оформление дистанционной работы</Link>
                    </li>
                  </div>
                  <div className={styles.second}>
                    <li className={styles.item}>
                      <Link to="/legal">Оценка наличия трудовых отношений</Link>
                    </li>
                    <li className={styles.item}>
                      <Link to="/legal">Увольнение сотрудника</Link>
                    </li>
                  </div>
                </div>
              </>
            )}
            {selectedService.accounting && (
              <>
                <li className={styles.item}>
                  <Link to="/accounting">Комплексное обслуживание</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/accounting">Подготовка первичных документов</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/accounting">Подготовка и сдача бухгалтерской и налоговой отчетности</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/accounting">Зарплатный проект</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/accounting">Учетная политика организации</Link>
                </li>
              </>
            )}
            {selectedService.hr && (
              <>
                <li className={styles.item}>
                  <Link to="/hr">Постановка организации на учет</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/hr">Аутсорсинг воинского учета</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/hr">Запрос комиссариата</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/hr">Кадровое администрирование</Link>
                </li>
                <li className={styles.item}>
                  <Link to="/hr">Перевод организации на КЭДО</Link>
                </li>
              </>
            )}
            {selectedService.offers && (
              <>
                <li className={styles.item}>
                  <Link to="/offers">Участникам стартапы России</Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className={styles.mobileRow}>
          <h2 className={styles.mobileTitle}>Все наши услуги</h2>
          <div className={styles.dropdowns}>
            <div className={styles.dropdown}>
              <div
                className={styles.dropdownBtn}
                onClick={() =>
                  setSelectedService({
                    corporate: !selectedService.corporate,
                    legal: selectedService.legal,
                    accounting: selectedService.accounting,
                    hr: selectedService.hr,
                    offers: selectedService.offers,
                    contacts: selectedService.contacts,
                  })
                }
              >
                <h2>
                  <img src="/images/header/corporate.svg" alt="Корпоративные услуги" />
                  Корпоративные услуги
                </h2>
                <img
                  className={selectedService.corporate ? `${styles.arrow} ${styles.active}` : styles.arrow}
                  src="/images/general/arrow.svg"
                  alt="arrow"
                />
              </div>
              {selectedService.corporate && (
                <ul className={styles.dropdownContent}>
                  <li className={styles.item}>
                    <Link to="/corporate">Регистрация ИП</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/corporate">Регистрация ООО</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/corporate">Регистрация НКО</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/corporate">Продажа доли в ООО</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/corporate">Ликвидация ИП</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/corporate">Ликвидация ООО</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/corporate">Ликвидация НКО</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/corporate">Подготовка индивидуального Устава</Link>
                  </li>
                </ul>
              )}
            </div>
            <hr className={styles.hr} />
            <div className={styles.dropdown}>
              <div
                className={styles.dropdownBtn}
                onClick={() =>
                  setSelectedService({
                    corporate: selectedService.corporate,
                    legal: !selectedService.legal,
                    accounting: selectedService.accounting,
                    hr: selectedService.hr,
                    offers: selectedService.offers,
                    contacts: selectedService.contacts,
                  })
                }
              >
                <h2>
                  <img src="/images/header/legal.svg" alt="Юридические услуги" />
                  Юридические услуги
                </h2>
                <img
                  className={selectedService.legal ? `${styles.arrow} ${styles.active}` : styles.arrow}
                  src="/images/general/arrow.svg"
                  alt="arrow"
                />
              </div>
              {selectedService.legal && (
                <ul className={styles.dropdownContent}>
                  <li className={styles.item}>
                    <Link to="/legal">Комплексное обслуживание</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/legal">Подготовка договора</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/legal">Экспертиза договора</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/legal">Подготовка доп. документов к договору</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/legal">Участие в переговорах</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/legal">Претензионная работа</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/legal">Проверка закрывающих документов</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/legal">Разработка трудового договора</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/legal">Оформление дистанционной работы</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/legal">Оценка наличия трудовых отношений</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/legal">Увольнение сотрудника</Link>
                  </li>
                </ul>
              )}
            </div>
            <hr className={styles.hr} />
            <div className={styles.dropdown}>
              <div
                className={styles.dropdownBtn}
                onClick={() =>
                  setSelectedService({
                    corporate: selectedService.corporate,
                    legal: selectedService.legal,
                    accounting: !selectedService.accounting,
                    hr: selectedService.hr,
                    offers: selectedService.offers,
                    contacts: selectedService.contacts,
                  })
                }
              >
                <h2>
                  <img src="/images/header/accounting.svg" alt="Бухгалтерские услуги" />
                  Бухгалтерские услуги
                </h2>
                <img
                  className={selectedService.accounting ? `${styles.arrow} ${styles.active}` : styles.arrow}
                  src="/images/general/arrow.svg"
                  alt="arrow"
                />
              </div>
              {selectedService.accounting && (
                <ul className={styles.dropdownContent}>
                  <li className={styles.item}>
                    <Link to="/accounting">Комплексное обслуживание</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/accounting">Подготовка первичных документов</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/accounting">Подготовка и сдача бухгалтерской и налоговой отчетности</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/accounting">Зарплатный проект</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/accounting">Учетная политика организации</Link>
                  </li>
                </ul>
              )}
            </div>
            <hr className={styles.hr} />
            <div className={styles.dropdown}>
              <div
                className={styles.dropdownBtn}
                onClick={() =>
                  setSelectedService({
                    corporate: selectedService.corporate,
                    legal: selectedService.legal,
                    accounting: selectedService.accounting,
                    hr: !selectedService.hr,
                    offers: selectedService.offers,
                    contacts: selectedService.contacts,
                  })
                }
              >
                <h2>
                  <img src="/images/header/hr.svg" alt="Кадровые услуги" />
                  Кадровые услуги
                </h2>
                <img
                  className={selectedService.hr ? `${styles.arrow} ${styles.active}` : styles.arrow}
                  src="/images/general/arrow.svg"
                  alt="arrow"
                />
              </div>
              {selectedService.hr && (
                <ul className={styles.dropdownContent}>
                  <li className={styles.item}>
                    <Link to="/hr">Постановка организации на учет</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/hr">Аутсорсинг воинского учета</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/hr">Запрос комиссариата</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/hr">Кадровое администрирование</Link>
                  </li>
                  <li className={styles.item}>
                    <Link to="/hr">Перевод организации на КЭДО</Link>
                  </li>
                </ul>
              )}
            </div>
            <hr className={styles.hr} />
            <div className={styles.dropdown}>
              <div
                className={styles.dropdownBtn}
                onClick={() =>
                  setSelectedService({
                    corporate: selectedService.corporate,
                    legal: selectedService.legal,
                    accounting: selectedService.accounting,
                    hr: selectedService.hr,
                    offers: !selectedService.offers,
                    contacts: selectedService.contacts,
                  })
                }
              >
                <h2>
                  <img src="/images/header/offers.svg" alt="Кадровые услуги" />
                  Акционные предложения
                </h2>
                <img
                  className={selectedService.offers ? `${styles.arrow} ${styles.active}` : styles.arrow}
                  src="/images/general/arrow.svg"
                  alt="arrow"
                />
              </div>
              {selectedService.offers && (
                <ul className={styles.dropdownContent}>
                  <li className={styles.item}>
                    <Link to="/offers">Победителям Студенчесткий стартап 5-я волна</Link>
                  </li>
                </ul>
              )}
            </div>
            <hr className={styles.hr} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
