import React from "react";

import styles from "./Military.module.scss";
import { Link } from "react-router-dom";
import Modal from "../../../UI/Modal/Modal";
import { IInclude } from "../../Home/Popular/Desktop/PopularDesktop";

const includeService01 = [
  {
    id: 0,
    text: "сверка всех необходимых документов штатных сотрудников, по обновленным правилам работы с персональными данными",
  },
  { id: 1, text: "определение приписки организации к военкомату" },
  { id: 2, text: "подготовка приказа об организации воинского учета" },
  { id: 3, text: "подготовка карточки учета организации" },
  { id: 4, text: "подготовка перечня должностных обязанностей работника осуществляющего воинский учет" },
  { id: 5, text: "разъяснения по заполнению карточки «Форма №10»" },
];

const includeService02 = [
  { id: 0, text: "услуга «Постановка организации на воинский учет»; " },
  { id: 1, text: "услуга «Запрос комиссариата»;" },
  {
    id: 2,
    text: "полная проверка картотеки организации, проверка на соответствие законодательству, приведение в надлежащий вид",
  },
  { id: 3, text: "обеспечение всех процессов при приеме или увольнении сотрудника" },
  { id: 4, text: "ежемесячный контроль сведений о сотрудниках" },
  { id: 5, text: "подготовка документов для сотрудника связанных с приходом повестки на организацию" },
  { id: 6, text: "разъяснение по бронированию сотрудников" },
];

const includeService03 = [
  { id: 0, text: "оценка содержания запроса" },
  { id: 1, text: "изменение стандартных документов по запросу комиссариата" },
  { id: 2, text: "подготовка уникальных документов по специфике субъектов" },
  { id: 3, text: "помощь в проведении сверки данных с военкоматом" },
  { id: 4, text: "помощь при проведении выездной проверки (разъяснение порядка действий, прав и обязанностей)" },
];

const Military: React.FC = () => {
  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "services" | "feedback" | null>(null);

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
  };

  return (
    <section className={styles.papers} id="military">
      {modalMode === "feedback" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" buyService={true} />
      )}
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
        />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={`${styles.label} sub-title`}>Воинский учет</div>
          <div className={styles.body}>
            <div className={styles.column} tabIndex={0}>
              <div className={styles.item}>
                <h3 className={styles.name}>Постановка организации на учет</h3>
                <p className={styles.text}>Поможем быстро и правильно подготовить документы</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Постановка организации на учет",
                      "За услугу",
                      15000,
                      `Каждая организация не зависимо от формы существования обязана встать на воинский учет. Поможем быстро и правильно подготовить документы для постановки организации на воинский учет под ключ.
										`,
                      includeService01,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 15 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={1}>
              <div className={styles.item}>
                <h3 className={styles.name}>Аутсорсинг воинского учета</h3>
                <p className={styles.text}>Дистанционное ведение воинского учета</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Аутсорсинг воинского учета",
                      "За услугу",
                      3500,
                      `В дистанционном формате поможем с текущими процессами по ведению воинского учета, в случаях увольнения или приема сотрудника, выхода работника на дистанционную работу
										`,
                      includeService02,
                      true,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 3 500 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={2}>
              <div className={styles.item}>
                <h3 className={styles.name}>Запрос комиссариата</h3>
                <p className={styles.text}>Быстрое и безопасное решение вопроса в случае проверок</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Запрос комиссариата",
                      "На один месяц",
                      1500,
                      `Вашу организацию ожидает проверка, либо вам поступили сведения о необходимости явки в военкомат? Поможем быстро и безопасно разрешить данный вопрос, с вашими минимальными затратами времени. 
										`,
                      includeService03,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 1 500 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.dropdowns}>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Постановка организации на учет",
                    "За услугу",
                    15000,
                    `Каждая организация не зависимо от формы существования обязана встать на воинский учет. Поможем быстро и правильно подготовить документы для постановки организации на воинский учет под ключ.
									`,
                    includeService01,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Постановка организации на учет</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Аутсорсинг воинского учета",
                    "За услугу",
                    3500,
                    `В дистанционном формате поможем с текущими процессами по ведению воинского учета, в случаях увольнения или приема сотрудника, выхода работника на дистанционную работу
									`,
                    includeService02,
                    true,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Аутсорсинг воинского учета</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Запрос комиссариата",
                    "На один месяц",
                    1500,
                    `Вашу организацию ожидает проверка, либо вам поступили сведения о необходимости явки в военкомат? Поможем быстро и безопасно разрешить данный вопрос, с вашими минимальными затратами времени. 
									`,
                    includeService03,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Запрос комиссариата</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Military;
