import React from "react";

import styles from "./../ContractLaw/ContractLaw.module.scss";

import { Link } from "react-router-dom";
import { IInclude } from "../../Home/Popular/Desktop/PopularDesktop";
import Modal from "../../../UI/Modal/Modal";

const includeService01 = [
  { id: 0, text: "разработка проекта трудового договора и внесение правок после согласования" },
  { id: 1, text: "рекомендации по определению трудовой функции в соответствии с ЕКСД" },
  { id: 2, text: "оценим возможности трудоустройства сотрудника по совместительству" },
  { id: 3, text: "поможем с заключением договора в формате «онлайн»" },
];

const includeService02 = [
  { id: 0, text: "подготовка ДС к трудовому договору о дистанционной работе" },
  { id: 1, text: "акты о передаче техники под ответственность" },
  { id: 2, text: "создание проекта приказа о переводе на дистанционную работу" },
  { id: 3, text: "рекомендации по контролю деятельности работника без нарушения его прав" },
];

const includeService03 = [
  { id: 0, text: "оценка фактических обстоятельств выполняемой работы" },
  { id: 1, text: "рекомендации по оптимизации условий, для квалификации отношений как гражданских" },
  { id: 2, text: "рекомендации по заключению трудового договора, в случае выявления трудовой функции" },
  { id: 3, text: "оценка налоговых издержек в случае заключения трудового договора" },
];

const includeService04 = [
  { id: 0, text: "оценка фактических обстоятельств" },
  { id: 1, text: "оценка условий трудового договора" },
  { id: 2, text: "заключение по вариантам разрешения вопроса с оценкой последствий" },
  { id: 3, text: "составление документов в соответствии с выбранным вариантом" },
];

const LaborLaw: React.FC = () => {
  const [isServicesActive, setIsServicesActive] = React.useState(false);

  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "services" | "feedback" | null>(null);

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
  };

  return (
    <section className={styles.papers} id="labor">
      {modalMode === "feedback" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" buyService={true} />
      )}
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
        />
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={`${styles.label} sub-title`}>Трудовое право</div>
          <div className={`${styles.body} ${isServicesActive ? styles.active : ""}`}>
            <div className={styles.column} tabIndex={0}>
              <div className={styles.item}>
                <h3 className={styles.name}>Разработка трудового договора</h3>
                <p className={styles.text}>Индивидуальная разработка трудового договора для Вашей компании</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Разработка трудового договора",
                      "На один месяц",
                      5000,
                      `Заключение трудового договора между работником и работодателем - важный этап законной работы Вашей компании. В этом документе закрепляются права и обязанности сторон трудовых отношений. Трудовой договор - гарант как для Вас, так и для Вашего сотрудника в части добросовестного соблюдения прав и исполнения обязанностей.
										`,
                      includeService01,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 5 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={1}>
              <div className={styles.item}>
                <h3 className={styles.name}>Оформление дистанционной работы</h3>
                <p className={styles.text}>Проведение процедуры вывода сотрудника в дистанционный формат работы</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Оформление дистанционной работы",
                      "На один месяц",
                      2500,
                      `Дистанционный сотрудник – новые, удобные, реалии которые не должны составлять проблем для работодателя. Для этого следует правильно провести процедуру вывода сотрудника в дистанционный формат работы, для чего у нас уже подготовлено решение! 
										`,
                      includeService02,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 2 500 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={2}>
              <div className={styles.item}>
                <h3 className={styles.name}>Оценка наличия трудовых отношений</h3>
                <p className={styles.text}>Профессиональная оценка и рекомендации</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Оценка наличия трудовых отношений",
                      "На один месяц",
                      3500,
                      `Штрафы за нарушение трудового законодательства увеличиваются с каждым днем, а схемы недобросовестных работодателей инспекции выявляют легче легкого, поэтому крайне важно достоверно понимать – присутствует ли в ваших правовых отношениях с сотрудником трудовая функция, требуется ли заключение трудового договора и все последствия выполнения либо невыполнения данного действия. 
										`,
                      includeService03,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div className={styles.buy}>
                  <p>от 5 000 ₽</p>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setModalMode("feedback");
                      setIsModalActive(true);
                    }}
                  >
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.column} tabIndex={3}>
              <div className={styles.item}>
                <h3 className={styles.name}>Увольнение сотрудника</h3>
                <p className={styles.text}>Поможем в данной ситуации с максимально благоприятным исходом</p>
                <Link
                  className={styles.more}
                  to=""
                  onClick={() => {
                    handleOpenModal(
                      "Увольнение сотрудника",
                      "На один месяц",
                      7500,
                      `У каждого работодателя может возникнуть вопрос, как уволить сотрудника, не нарушая закон. Способов, предусмотренных Трудовым кодексом РФ, не так много, мы окажем Вам помощь в данной ситуации с максимально благоприятным исходом. 
										`,
                      includeService04,
                      false,
                      ""
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  Подробнее
                </Link>
                <div
                  className={styles.buy}
                  onClick={() => {
                    setModalMode("feedback");
                    setIsModalActive(true);
                  }}
                >
                  <p>от 5 000 ₽</p>
                  <div className={styles.button}>
                    <button>Купить</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.dropdowns} ${isServicesActive ? styles.active : ""}`}>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Разработка трудового договора",
                    "На один месяц",
                    5000,
                    `Заключение трудового договора между работником и работодателем - важный этап законной работы Вашей компании. В этом документе закрепляются права и обязанности сторон трудовых отношений. Трудовой договор - гарант как для Вас, так и для Вашего сотрудника в части добросовестного соблюдения прав и исполнения обязанностей.
									`,
                    includeService01,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Разработка трудового договора</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Оформление дистанционной работы",
                    "На один месяц",
                    2500,
                    `Дистанционный сотрудник – новые, удобные, реалии которые не должны составлять проблем для работодателя. Для этого следует правильно провести процедуру вывода сотрудника в дистанционный формат работы, для чего у нас уже подготовлено решение! 
									`,
                    includeService02,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Оформление дистанционной работы</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Оценка наличия трудовых отношений",
                    "На один месяц",
                    3500,
                    `Штрафы за нарушение трудового законодательства увеличиваются с каждым днем, а схемы недобросовестных работодателей инспекции выявляют легче легкого, поэтому крайне важно достоверно понимать – присутствует ли в ваших правовых отношениях с сотрудником трудовая функция, требуется ли заключение трудового договора и все последствия выполнения либо невыполнения данного действия. 
									`,
                    includeService03,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Оценка наличия трудовых отношений</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className={styles.dropdown}>
              <div
                className={styles.item}
                onClick={() => {
                  handleOpenModal(
                    "Увольнение сотрудника",
                    "На один месяц",
                    7500,
                    `У каждого работодателя может возникнуть вопрос, как уволить сотрудника, не нарушая закон. Способов, предусмотренных Трудовым кодексом РФ, не так много, мы окажем Вам помощь в данной ситуации с максимально благоприятным исходом. 
									`,
                    includeService04,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                <h2>Увольнение сотрудника</h2>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 26L23 18L15 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
          <div
            className={`${styles.all} ${isServicesActive ? styles.active : ""}`}
            onClick={() => setIsServicesActive(!isServicesActive)}
          >
            <button>
              {isServicesActive ? "Свернуть" : "Показать все услуги"}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10L12 15L17 10" stroke="#6A6F74" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LaborLaw;
