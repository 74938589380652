import React from "react";

import styles from "./Desktop/ServicesDesktop.module.scss";

import ServicesDesktop from "./Desktop/ServicesDesktop";
import ServicesTablet from "./Tablet/ServicesTablet";
import ServicesMobile from "./Mobile/ServicesMobile";

const Services: React.FC = () => {
  return (
    <section className={styles.services}>
      <div className="container">
        <ServicesDesktop />
        <ServicesTablet />
      </div>
      <ServicesMobile />
    </section>
  );
};

export default Services;
