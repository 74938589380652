import React from "react";

import styles from "./Header.module.scss";

import { IService } from "./Header";
import { Link } from "react-router-dom";
import Modal from "../../UI/Modal/Modal";
import { IInclude } from "../../pages/Home/Popular/Desktop/PopularDesktop";

interface HeaderDesktopProps {
  isMenuActive: boolean;
  setIsMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
  selectedService: IService;
  setSelectedService: React.Dispatch<React.SetStateAction<IService>>;
}

const includeService01 = [
  { id: 0, text: "подготовка заявления на регистрацию в онлайн формате" },
  { id: 1, text: "проверка комплектности пакета документов" },
  { id: 2, text: "помощь с подбором ОКВЭД в зависимости от предполагаемой деятельности" },
  { id: 3, text: "помощь с подбором оптимального режима налогообложения" },
];
const includeService02 = [
  { id: 0, text: "подготовка формы Р11001" },
  { id: 1, text: "помощь с подбором оптимального типового устава" },
  { id: 2, text: "подготовка решения учредителя" },
  { id: 3, text: "помощь с подбором ОКВЭД в зависимости от предполагаемой деятельности" },
  { id: 4, text: "помощь с подбором оптимального режима налогообложения" },
  { id: 5, text: "заполнение формы перехода не иной режим налогообложения" },
  { id: 6, text: "подача документов в формате онлайн (*при наличии ЭЦП)" },
];
const includeService03 = [
  { id: 0, text: "оценка исходного документа" },
  { id: 1, text: "оценка фактических обстоятельств" },
  { id: 2, text: "учет всех требований закона к совершаемым действиям" },
  { id: 3, text: "подготовка юридически точного документа, без скрытых рисков" },
];
const includeService04 = [
  { id: 0, text: "сверка с ФНС" },
  { id: 1, text: "подготовка полного пакета документов" },
  { id: 2, text: "помощь с подбором ОКВЭД в зависимости от предполагаемой деятельности" },
  { id: 3, text: "дистанционная подача (* при наличии действующего ЭЦП)" },
];
const includeService05 = [
  { id: 0, text: "оценка возможности упрощенной процедуры" },
  { id: 1, text: "оценка рисков предъявления требований от контрагентов" },
  { id: 2, text: "подготовка документов для ФНС" },
  { id: 3, text: "подача документов на ликвидацию" },
  { id: 4, text: "получения записи о ликвидации Организации" },
];
const includeService06 = [
  { id: 0, text: "оценка потребностей заказчика действующему законодательству" },
  { id: 1, text: "подготовка проекта Устава" },
  { id: 2, text: "участие в переговорах при согласовании" },
  { id: 3, text: "подача документов в регистрирующий орган" },
];
const includeService07 = [
  { id: 0, text: "бесплатная первичная консультация по всем заявленным критериям к договору" },
  { id: 1, text: "разработка проекта договора с учетом коммерческих условий, необходимых Клиенту" },
  { id: 2, text: "составление необходимых юридических приложений к Договору" },
  { id: 3, text: "участие в согласовании положений договора с контрагентами Клиента, ведение переговоров" },
  { id: 4, text: "анализ налоговых и правовых последствий положений, включаемых в договор" },
  { id: 5, text: "проверка предоставленных контрагентом документов и сведений" },
];
const includeService08 = [
  { id: 0, text: "оценка сведений о контрагенте по открытиям источникам" },
  { id: 1, text: "выявление ошибок юридического характера, некорректных данных" },
  { id: 2, text: "определение условий, не позволяющих считать договор заключенным" },
  { id: 3, text: "выявление скрытых условий" },
  { id: 4, text: "предложение альтернативных вариантов заключения договора, в том числе путем изменение его формы" },
];
const includeService09 = [
  { id: 0, text: "оценка исходного документа" },
  { id: 1, text: "оценка фактических обстоятельств" },
  { id: 2, text: "учет всех требований закона к совершаемым действиям" },
  { id: 3, text: "подготовка юридически точного документа, без скрытых рисков" },
];
const includeService10 = [
  { id: 0, text: "подготовка к проведению переговоров" },
  { id: 1, text: "рассмотрение предложений Вашего контрагента" },
  { id: 2, text: "формулирование вопросов и их редактура" },
  { id: 3, text: "обсуждение предложений и последствий различных вариантов" },
];
const includeService11 = [
  { id: 0, text: "разработка проекта трудового договора и внесение правок после согласования" },
  { id: 1, text: "рекомендации по определению трудовой функции в соответствии с ЕКСД" },
  { id: 2, text: "оценим возможности трудоустройства сотрудника по совместительству" },
  { id: 3, text: "поможем с заключением договора в формате «онлайн»" },
];
const includeService12 = [
  { id: 0, text: "подготовка ДС к трудовому договору о дистанционной работе" },
  { id: 1, text: "акты о передаче техники под ответственность" },
  { id: 2, text: "создание проекта приказа о переводе на дистанционную работу" },
  { id: 3, text: "рекомендации по контролю деятельности работника без нарушения его прав" },
];
const includeService13 = [
  { id: 0, text: "оценка фактических обстоятельств выполняемой работы" },
  { id: 1, text: "рекомендации по оптимизации условий, для квалификации отношений как гражданских" },
  { id: 2, text: "рекомендации по заключению трудового договора, в случае выявления трудовой функции" },
  { id: 3, text: "оценка налоговых издержек в случае заключения трудового договора" },
];
const includeService14 = [
  { id: 0, text: "оценка фактических обстоятельств" },
  { id: 1, text: "оценка условий трудового договора" },
  { id: 2, text: "заключение по вариантам разрешения вопроса с оценкой последствий" },
  { id: 3, text: "составление документов в соответствии с выбранным вариантом" },
];
const includeService15 = [
  { id: 0, text: "счет на оплату" },
  { id: 1, text: "счет-фактура" },
  { id: 2, text: "акт выполненных работ" },
  { id: 3, text: "накладная" },
  { id: 4, text: "приходный кассовый ордер" },
  { id: 5, text: "расходный кассовый ордер" },
  { id: 6, text: "акт ОС" },
  { id: 7, text: "авансовый отчет" },
  { id: 8, text: "акт взаимозачета" },
  { id: 9, text: "акт сверки расчётов" },
  { id: 10, text: "требование-накладная на списание материалов (перемещение)" },
];
const includeService16 = [
  { id: 0, text: "сдача «нулевой» отчетности" },
  { id: 1, text: "подготовка отчетности в СФР, ФНС, Росстат в формате ЭДО" },
  { id: 2, text: "предварительная проверка отчетности на наличие ошибок по алгоритмам государственных органов" },
  { id: 3, text: "предоставление календаря с перечнем сдаваемых отчетов" },
  { id: 4, text: "получение моментального уведомления от государственных органов о принятом отчете" },
  { id: 5, text: "бесплатное исправление ошибок в случае отказа государственного органа" },
];
const includeService17 = [
  { id: 0, text: "расчеты заработной платы работника по отработанному времени" },
  { id: 1, text: "расчет дополнительных выплат (больничные, отпускные, командировочные)" },
  { id: 2, text: "расчет по налоговым выплатам" },
  { id: 3, text: "НДФЛ" },
  { id: 4, text: "страховые взносы" },
  { id: 5, text: "удержания из итоговой выплаты" },
];
const includeService18 = [
  { id: 0, text: "проведение детального анализа имеющейся учетной политики" },
  { id: 1, text: "оценка фактических обстоятельств ведения бизнеса" },
  { id: 2, text: "разработка учетной политики с учетом специфики бизнеса клиента" },
  { id: 3, text: "помощь при внедрении учетной политики в организации" },
];
const includeService19 = [
  {
    id: 0,
    text: "сверка всех необходимых документов штатных сотрудников, по обновленным правилам работы с персональными данными",
  },
  { id: 1, text: "определение приписки организации к военкомату" },
  { id: 2, text: "подготовка приказа об организации воинского учета" },
  { id: 3, text: "подготовка карточки учета организации" },
  { id: 4, text: "подготовка перечня должностных обязанностей работника осуществляющего воинский учет" },
  { id: 5, text: "разъяснения по заполнению карточки «Форма №10»" },
];
const includeService20 = [
  { id: 0, text: "услуга «Постановка организации на воинский учет»; " },
  { id: 1, text: "услуга «Запрос комиссариата»;" },
  {
    id: 2,
    text: "полная проверка картотеки организации, проверка на соответствие законодательству, приведение в надлежащий вид",
  },
  { id: 3, text: "обеспечение всех процессов при приеме или увольнении сотрудника" },
  { id: 4, text: "ежемесячный контроль сведений о сотрудниках" },
  { id: 5, text: "подготовка документов для сотрудника связанных с приходом повестки на организацию" },
  { id: 6, text: "разъяснение по бронированию сотрудников" },
];
const includeService21 = [
  { id: 0, text: "оценка содержания запроса" },
  { id: 1, text: "изменение стандартных документов по запросу комиссариата" },
  { id: 2, text: "подготовка уникальных документов по специфике субъектов" },
  { id: 3, text: "помощь в проведении сверки данных с военкоматом" },
  { id: 4, text: "помощь при проведении выездной проверки (разъяснение порядка действий, прав и обязанностей)" },
];
const includeService22 = [
  {
    id: 0,
    text: "первичный кадровый учет с оформлением личного дела сотрудника",
  },
  { id: 1, text: "оформление приёма на работу, и изменений" },
  { id: 2, text: "оформление кадровых документов при предоставлении отпуска" },
  { id: 3, text: "подготовка графика отпусков" },
  { id: 4, text: "подготовка штатного расписания и приказа о его утверждении" },
  { id: 5, text: "оформление табеля учета рабочего времени" },
];

const HeaderDesktop: React.FC<HeaderDesktopProps> = ({ isMenuActive, setIsMenuActive, selectedService, setSelectedService }) => {
  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "compare" | "feedback" | null>(null);

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
  };

  return (
    <>
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
        />
      )}
      <nav onClick={() => setIsMenuActive(false)} className={isMenuActive ? `${styles.menuPc} ${styles.active}` : styles.menuPc}>
        <div className={styles.menuContent} onClick={(e) => e.stopPropagation()}>
          <ul className={styles.services}>
            <Link to="/corporate" className={styles.title}>
              <img src="/images/header/corporate.svg" alt="Корпоративные услуги" />
              Корпоративные услуги
            </Link>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Регистрация ИП",
                    "На один месяц",
                    4500,
                    `Поможем зарегистрировать ИП в любой точке России быстро и безошибочно. Берем на себя полный цикл: от выбора системы налогообложения и подготовки документов до помощи в открытии счета на оптимальных условиях.
										`,
                    includeService01,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Регистрация ИП
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Регистрация ООО",
                    "За услугу",
                    5000,
                    `Открытие юридического лица в формате общества с ограниченной ответственностью – один из наиболее популярных в РФ способов ведения бизнеса. Мы поможем с открытием Общества под ключ и с гарантией.
										`,
                    includeService02,
                    false,
                    "От 7 500 ₽ / месяц"
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Регистрация ООО
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Подготовка дополнительных документов к договору",
                    "На один месяц",
                    7500,
                    `В процессе осуществления договора могут возникнуть обстоятельства не предусмотренные сторонами в момент подписания, на возможности разрешения которых у сторон может быть схожая позиция, либо же наоборот – противоположная. Оформить выход из договора, либо подготовить документ, закрепляющий согласие сторон по разрешению вопроса поможет профессиональный юрист по договорной работе. 
										`,
                    includeService03,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Подготовка доп. документов к договору
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Закрытие ИП",
                    "За услугу",
                    9000,
                    `Планируете прекратить свою деятельность? Сделать это легально можно только одним путем – закрыть ИП. Поможем со всем процедурой быстро и с гарантией результата. 
										`,
                    includeService04,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Закрытие ИП
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Ликвидация ООО",
                    "За услугу",
                    15000,
                    `Поможем с полной и упрощенной процедурой прекращения деятельности Организации с гарантией результата и без задержек по времени. 
										`,
                    includeService05,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Ликвидация ООО
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Подготовка индивидуального Устава",
                    "За услугу",
                    20000,
                    `Устав – один из важнейших документов в организации, являющийся основой для всей деятельности на долгие годы. Правильно подготовленный Устав поможет вам не только достоверно оценивать перспективу деятельности, но и избежать изматывающих споров, в случае возникновения конфликта. 
										`,
                    includeService06,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Подготовка индивидуального Устава
              </div>
            </li>
          </ul>
          <ul className={styles.services}>
            <Link to="/legal" className={styles.title}>
              <img src="/images/header/legal.svg" alt="Юридические услуги" />
              Юридические услуги
            </Link>
            <li className={styles.item}>
              <Link to="/legal">Комплексное обслуживание</Link>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Подготовка договора",
                    "На один месяц",
                    5000,
                    `Правильно подготовленный договор – основа безопасности для любого бизнеса. Юрист по договорной работе оценит ваш запрос, направит уточняющие вопросы и даст разъяснения о необходимости наличия, либо же исключения, положений из договора. Договор, разработанный индивидуально для вас будет являться вашей собственностью, что гарантирует отсутствие претензий от лиц, размещающих шаблоны договора в открытом доступе. 
										`,
                    includeService07,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Подготовка договора
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Экспертиза договора",
                    "За услугу",
                    5000,
                    `Комплексный анализ текста договора на наличие скрытых условий и положений, не позволяющих считать его залеченным даже после подписания – одна из основных задач юриста по договорной работе. Профессиональная оценка поможет вам избавиться от недобросовестных контрагентов и заранее понимать все права и обязанности на период реализации сделки. 
										`,
                    includeService08,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Экспертиза договора
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Подготовка доп. документов к договору",
                    "На один месяц",
                    1500,
                    `В процессе осуществления договора могут возникнуть обстоятельства не предусмотренные сторонами в момент подписания, на возможности разрешения которых у сторон может быть схожая позиция, либо же наоборот – противоположная. Оформить выход из договора, либо подготовить документ, закрепляющий согласие сторон по разрешению вопроса поможет профессиональный юрист по договорной работе. 
										`,
                    includeService09,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Подготовка доп. документов к договору
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Участие в переговорах",
                    "На один месяц",
                    7500,
                    `Сложные, затяжные, без ясного понимания запросов контрагентов – все это могут быть переговоры. Подобрать правильные вопросы, а также моментально выявить противоречащие закону условия, предлагаемые контрагентом, вам поможет юрист по договорному праву.
										`,
                    includeService10,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Участие в переговорах
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Разработка трудового договора",
                    "На один месяц",
                    5000,
                    `Заключение трудового договора между работником и работодателем - важный этап законной работы Вашей компании. В этом документе закрепляются права и обязанности сторон трудовых отношений. Трудовой договор - гарант как для Вас, так и для Вашего сотрудника в части добросовестного соблюдения прав и исполнения обязанностей.
										`,
                    includeService11,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Разработка трудового договора
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Оформление дистанционной работы",
                    "На один месяц",
                    2500,
                    `Дистанционный сотрудник – новые, удобные, реалии которые не должны составлять проблем для работодателя. Для этого следует правильно провести процедуру вывода сотрудника в дистанционный формат работы, для чего у нас уже подготовлено решение! 
										`,
                    includeService12,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Оформление дистанционной работы
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Оценка наличия трудовых отношений",
                    "На один месяц",
                    3500,
                    `Штрафы за нарушение трудового законодательства увеличиваются с каждым днем, а схемы недобросовестных работодателей инспекции выявляют легче легкого, поэтому крайне важно достоверно понимать – присутствует ли в ваших правовых отношениях с сотрудником трудовая функция, требуется ли заключение трудового договора и все последствия выполнения либо невыполнения данного действия. 
										`,
                    includeService13,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Оценка наличия трудовых отношений
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Увольнение сотрудника",
                    "На один месяц",
                    7500,
                    `У каждого работодателя может возникнуть вопрос, как уволить сотрудника, не нарушая закон. Способов, предусмотренных Трудовым кодексом РФ, не так много, мы окажем Вам помощь в данной ситуации с максимально благоприятным исходом. 
										`,
                    includeService14,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Увольнение сотрудника
              </div>
            </li>
          </ul>
          <ul className={styles.services}>
            <Link to="/accounting" className={styles.title}>
              <img src="/images/header/accounting.svg" alt="Бухгалтерские услуги" />
              Бухгалтерские услуги
            </Link>
            <li className={styles.item}>
              <Link to="/accounting">Комплексное обслуживание</Link>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Подготовка первичных документов",
                    "На один месяц",
                    500,
                    `Подготовка первичных документов бухгалтерского учета требует знаний не только основ бухгалтерского учета, но и понимания юриспруденции в части налогового законодательства, именно поэтому контроль за оказанием данной услуги ведет главный бухгалтер. При необходимости, документы создаются в машиночитаемом формате для загрузки в банки и осуществления электронного документооборота.
										`,
                    includeService15,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Подготовка первичных документов
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Подготовка и сдача бухгалтерской и налоговой отчетности",
                    "На один месяц",
                    0,
                    `Подготовка первичных документов бухгалтерского учета требует знаний не только основ бухгалтерского учета, но и понимания юриспруденции в части налогового законодательства, именно поэтому контроль за оказанием данной услуги ведет главный бухгалтер. При необходимости, документы создаются в машиночитаемом формате для загрузки в банки и осуществления электронного документооборота.
										`,
                    includeService16,
                    false,
                    "Стоимость рассчитывается в индивидуальном порядке"
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Подготовка и сдача бухгалтерской и налоговой отчетности
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Зарплатный проект",
                    "На один месяц",
                    4500,
                    `Расчет заработной платы работников по окладу и другим системам оплаты труда, осуществляют только профессиональные бухгалтеры. Мы поможем избавиться от неточности в начислениях, которые могут привести к обращению сотрудника в трудовую инспекцию. 
										`,
                    includeService17,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Зарплатный проект
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Учетная политика организации",
                    "На один месяц",
                    7500,
                    `Организуем систему, которая позволит вам правильно вести работу, а также избежать лишних налоговых издержек. Профессиональный главный бухгалтер оценит фактическую структуру организации и финансовый план развития, что позволит создать уникальную систему формирования учетной политики организации учитывающие все ваши интересы. 
										`,
                    includeService18,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Учетная политика организации
              </div>
            </li>
          </ul>
          <ul className={styles.services}>
            <Link to="/hr" className={styles.title}>
              <img src="/images/header/hr.svg" alt="Кадровые услуги" />
              Кадровые услуги
            </Link>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Постановка организации на учет",
                    "За услугу",
                    15000,
                    `Каждая организация не зависимо от формы существования обязана встать на воинский учет. Поможем быстро и правильно подготовить документы для постановки организации на воинский учет под ключ.
										`,
                    includeService19,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Постановка организации на учет
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Аутсорсинг воинского учета",
                    "За услугу",
                    3500,
                    `В дистанционном формате поможем с текущими процессами по ведению воинского учета, в случаях увольнения или приема сотрудника, выхода работника на дистанционную работу
										`,
                    includeService20,
                    true,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Аутсорсинг воинского учета
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Запрос комиссариата",
                    "На один месяц",
                    1500,
                    `Вашу организацию ожидает проверка, либо вам поступили сведения о необходимости явки в военкомат? Поможем быстро и безопасно разрешить данный вопрос, с вашими минимальными затратами времени. 
										`,
                    includeService21,
                    false,
                    ""
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Запрос комиссариата
              </div>
            </li>
            <li className={styles.item}>
              <div
                onClick={() => {
                  handleOpenModal(
                    "Кадровое администрирование",
                    "За услугу",
                    5000,
                    `Кадровое делопроизводство направлено на создание удобной системы для работы с документацией, при которой работодатель может наладить эффективное взаимодействие с рабочим персоналом и надзорными органами в части своевременного предоставления документов, а работник — получать необходимые бумаги и кадровую поддержку со стороны работодателя в установленные сроки.
										`,
                    includeService22,
                    false,
                    "от 5 000 ₽ / месяц"
                  );
                  setModalMode("more");
                  setIsModalActive(true);
                }}
              >
                Кадровое администрирование
              </div>
            </li>
          </ul>
          <ul className={styles.services}>
            <Link to="/offers" className={styles.title}>
              <img src="/images/header/offers.svg" alt="Акционные предложения" />
              Акционные предложения
            </Link>
            <ul className={styles.services} style={{ marginTop: 36 }}>
              <h2 className={styles.title}>
                <img src="/images/header/contacts.svg" alt="Контакты" />
                Контакты
              </h2>
              <div className={styles.item}>
                <Link to="/about">О нашей компании</Link>
              </div>
              <div className={styles.item}>
                <p>Пн-Пт, 9:00 - 18:00</p>
              </div>
              <div className={styles.item}>
                <p>+7 (964) 937-27-58</p>
              </div>
              <div className={styles.item}>
                <ul className={styles.medias}>
                  <li>
                    <Link to="https://vk.com/lexleg">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.5442 5.88156C21.6816 5.37375 21.5442 5 20.8833 5H18.6965C18.1395 5 17.8834 5.32094 17.746 5.67844C17.746 5.67844 16.6322 8.64812 15.0579 10.5737C14.5493 11.1303 14.3154 11.3091 14.0369 11.3091C13.8995 11.3091 13.6879 11.1303 13.6879 10.6225V5.88156C13.6879 5.27219 13.532 5 13.0716 5H9.63352C9.28452 5 9.0766 5.28437 9.0766 5.54844C9.0766 6.12531 9.86371 6.25937 9.9454 7.88437V11.4106C9.9454 12.1825 9.81916 12.3247 9.5407 12.3247C8.79814 12.3247 6.99372 9.34281 5.92443 5.93031C5.70909 5.26813 5.49746 5 4.93683 5H2.74999C2.12624 5 2 5.32094 2 5.67844C2 6.31219 2.74256 9.46063 5.45662 13.6206C7.26475 16.4603 9.81173 18 12.1285 18C13.5208 18 13.6916 17.6587 13.6916 17.0697C13.6916 14.3559 13.5654 14.1 14.2634 14.1C14.5864 14.1 15.1433 14.2787 16.4428 15.6478C17.9279 17.2728 18.173 18 19.0046 18H21.1915C21.8152 18 22.1308 17.6587 21.9489 16.9844C21.5331 15.5666 18.7225 12.6497 18.5962 12.4547C18.2732 11.9997 18.366 11.7966 18.5962 11.3903C18.5999 11.3862 21.2694 7.275 21.5442 5.88156Z" />
                      </svg>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/lexlegal_rus?igsh=MWQ2bzZkeDF0bHVlOQ==">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
                        <path
                          d="M16,21H8c-2.761,0-5-2.239-5-5V8c0-2.761,2.239-5,5-5h8c2.761,0,5,2.239,5,5v8  C21,18.761,18.761,21,16,21z"
                          opacity=".35"
                        ></path>
                        <path d="M12,7c-2.761,0-5,2.239-5,5s2.239,5,5,5s5-2.239,5-5S14.761,7,12,7z M12,15c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3  S13.657,15,12,15z"></path>
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div>
            </ul>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default HeaderDesktop;
