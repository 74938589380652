import React from "react";

import styles from "./Steps.module.scss";

const Steps: React.FC = () => {
  return (
    <section className={styles.steps}>
      <div className={styles.container}>
        <h2 className={`${styles.label} title`}>Как у нас устроена работа с клиентами</h2>
        <div className={styles.body}>
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <img src="/images/main/steps/first.svg" alt="1" />
              </div>
              <div className={styles.info}>
                <h3 className={styles.stepName}>Обращение</h3>
                <p className={styles.text}>Регистрируем входящую заявку и тщательно обрабатываем ваше обращение</p>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <img src="/images/main/steps/second.svg" alt="2" />
              </div>
              <div className={styles.info}>
                <h3 className={styles.stepName}>Экспертиза</h3>
                <p className={styles.text}>Наши специалисты проводят детальную экспертизу возникшей ситуации </p>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <img src="/images/main/steps/third.svg" alt="3" />
              </div>
              <div className={styles.info}>
                <h3 className={styles.stepName}>Решение</h3>
                <p className={styles.text}>Предлагаем решение вашей проблемы, подписываем договор, переходим к реализации</p>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <img src="/images/main/steps/fourth.svg" alt="4" />
              </div>
              <div className={styles.info}>
                <h3 className={styles.stepName}>Результат</h3>
                <p className={styles.text}>Оперативно решаем вашу ситуацию и фиксируем результат </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Steps;
