import React from "react";

import Layout from "../components/Layout/Layout";
import Complex from "../components/pages/Legal/Complex/Complex";
import ContractLaw from "../components/pages/Legal/ContractLaw/ContractLaw";
import LaborLaw from "../components/pages/Legal/LaborLaw/LaborLaw";
import Consult from "../components/pages/Consult/Consult";
import Info from "../components/Info/Info";

const LegalPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="legal-page">
      <Layout>
        <Info mode="legal" />
        <Complex />
        <ContractLaw />
        <LaborLaw />
        <Consult />
      </Layout>
    </div>
  );
};

export default LegalPage;
