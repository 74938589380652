import React from "react";

import styles from "./Complex.module.scss";
import { IInclude } from "../../Home/Popular/Desktop/PopularDesktop";
import Modal from "../../../UI/Modal/Modal";

const includeService01 = [
  {
    id: 0,
    text: "Консультации по правовым вопросам бизнеса через мессенджер (гражданское, корпоративное, предпринимательское право)",
  },
  { id: 1, text: "Шаблоны самых необходимых договоров (купля-продажа, аренда, подряд)" },
  {
    id: 2,
    text: "Экспертиза договоров, полученных от контрагентов на наличие скрытых платежей, заложенных просрочек, заведомо невыполнимых условий",
  },
  { id: 3, text: "Подготовка дополнительных соглашений, актов, протоков к договорам" },
  { id: 4, text: "Проверка закрывающих документов по сделкам" },
];
const setWhoWillSuit01 = [
  {
    id: 0,
    text: "ИП без сотрудников до 5-ти сделок в месяц",
  },
  { id: 1, text: "плательщикам налога НПД (самозанятым) до 5-ти сделок в месяц" },
];

const includeService02 = [
  {
    id: 0,
    text: "Консультации по правовым вопросам бизнеса через мессенджер (гражданское, корпоративное, предпринимательское, трудовое, судебное, налоговое право)",
  },
  {
    id: 1,
    text: "Предоставление базы шаблонных договоров, среди которых: купля-продажа, подряд, аренда, оферта на сайт, договор на разработку сайта, договор поставки оборудования и иные самые востребованные договоры",
  },
  {
    id: 2,
    text: "Экспертиза договоров, полученных от контрагентов на наличие скрытых платежей, заложенных просрочек, заведомо невыполнимых условий",
  },
  { id: 3, text: "Эксклюзивные консультации с участием группы сотрудников разных профилей в формате видео звонка" },
  {
    id: 4,
    text: "Разработка индивидуальных договоров по вашим требованиям и подбор компромиссных условий для согласования с контрагентом",
  },
  {
    id: 5,
    text: "Экспертиза договоров, полученных от контрагентов на наличие скрытых платежей, заложенных просрочек, заведомо невыполнимых условий",
  },
  {
    id: 6,
    text: "Подготовка дополнительных соглашений, актов, протоков к договорам",
  },
  {
    id: 7,
    text: "Проверка закрывающих документов по сделкам",
  },
  {
    id: 8,
    text: "Разработка трудовых договоров, в том числе для сотрудников в формате дистанционной работы",
  },
  {
    id: 9,
    text: "Аудит и актуализации политики компании по работке с персональными данными",
  },
  {
    id: 10,
    text: "Оценка судебной перспективы разрешения спора",
  },
  {
    id: 11,
    text: "Подготовка претензий, уведомлений, исков к контрагентам и сотрудникам за невыполнение или некачественное выполнение работ",
  },
];
const setWhoWillSuit02 = [
  {
    id: 0,
    text: "ООО до 7-ми сделок в месяц",
  },
  { id: 1, text: "ИП до 7-ми сделок в месяц" },
];

const includeService03 = [
  {
    id: 0,
    text: "Консультации по правовым вопросам бизнеса через мессенджер (гражданское, корпоративное, предпринимательское, трудовое, судебное, налоговое право)",
  },
  {
    id: 1,
    text: "Эксклюзивные консультации с участием группы сотрудников разных профилей в формате видео звонка",
  },
  {
    id: 2,
    text: "Экспертиза договоров, полученных от контрагентов на наличие скрытых платежей, заложенных просрочек, заведомо невыполнимых условий",
  },
  { id: 3, text: "Участие юриста-переговорщика, для быстрого и безопасного совершения сделки" },
  {
    id: 4,
    text: "Предоставление базы шаблонных договоров, среди которых: купля-продажа, подряд, аренда, оферта на сайт, договор на разработку сайта, договор поставки оборудования и иные самые востребованные договоры",
  },
  {
    id: 5,
    text: "Разработка индивидуальных договоров по вашим требованиям и подбор компромиссных условий для согласования с контрагентом",
  },
  {
    id: 6,
    text: "Экспертиза договоров, полученных от контрагентов на наличие скрытых платежей, заложенных просрочек, заведомо невыполнимых условий",
  },
  {
    id: 7,
    text: "Подготовка дополнительных соглашений, актов, протоков к договорам",
  },
  {
    id: 8,
    text: "Проверка закрывающих документов по сделкам",
  },
  {
    id: 8,
    text: "Разработка трудовых договоров, в том числе для сотрудников в формате дистанционной работы",
  },
  {
    id: 9,
    text: "Аудит и актуализации политики компании по работке с персональными данными",
  },
  {
    id: 10,
    text: "Оценка судебной перспективы разрешения спора",
  },
  {
    id: 11,
    text: "Подготовка претензий, уведомлений, исков к контрагентам и сотрудникам за невыполнение или некачественное выполнение работ",
  },
  {
    id: 12,
    text: "Работа с документами на иностранных языках",
  },
];
const setWhoWillSuit03 = [
  {
    id: 0,
    text: "ООО до 10-ти сотрудников",
  },
  { id: 1, text: "ИП до 10-ти сотрудников" },
];

const Complex: React.FC = () => {
  const [isModalActive, setIsModalActive] = React.useState(false);
  const [modalMode, setModalMode] = React.useState<"more" | "compare" | "feedback" | null>(null);

  const [isSlideActive, setIsSlideActive] = React.useState(false);
  const [isMouseDown, setIsMouseDown] = React.useState({
    zero: false,
    light: false,
    minimum: false,
  });

  const handleMouseDown = (type: string) => {
    setIsMouseDown({
      zero: type === "zero",
      light: type === "light",
      minimum: type === "minimum",
    });
  };

  const [modalTitle, setModalTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [include, setInclude] = React.useState<IInclude[]>(includeService01);
  const [perMounth, setPerMouth] = React.useState(false);
  const [individualPrice, setIndividualPrice] = React.useState("");
  const [whoWillSuitDescription, setWhoWillSuitDescription] = React.useState<IInclude[]>(setWhoWillSuit01);

  const handleOpenModal = (
    title: string,
    subTitle: string,
    price: number,
    description: string,
    include: IInclude[],
    perMounth: boolean,
    individualPrice: string,
    whoWillSuitDescription: IInclude[]
  ) => {
    setModalTitle(title);
    setSubTitle(subTitle);
    setPrice(price);
    setDescription(description);
    setInclude(include);
    setPerMouth(perMounth);
    setIsModalActive(true);
    setIndividualPrice(individualPrice);
    setWhoWillSuitDescription(whoWillSuitDescription);
  };

  return (
    <section className={styles.rate} id="complex">
      {modalMode === "feedback" && (
        <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="feedback" buyService={true} />
      )}
      {modalMode === "compare" && <Modal isModalActive={isModalActive} setIsModalActive={setIsModalActive} mode="compare" />}
      {modalMode === "more" && (
        <Modal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          mode="more"
          title={modalTitle}
          subTitle={subTitle}
          price={price}
          description={description}
          includeDescription={include}
          perMounth={perMounth}
          individualPrice={individualPrice}
          isRate={true}
          whoWillSuitDescription={whoWillSuitDescription}
        />
      )}
      <div className={styles.container}>
        <div className={styles.row}>
          <h2 className={`${styles.title} sub-title`}>Комплексное обслуживание</h2>
          <div className={`${styles.body} ${isSlideActive ? styles.slide : ""}`}>
            <div
              onTouchStart={() => handleMouseDown("zero")}
              className={`${styles.column} ${isMouseDown.zero ? styles.active : ""}`}
            >
              <div className={styles.item}>
                <h3 className={styles.label}>Первый</h3>
                <p className={styles.labelDescription}>Минимальный набор самых популярных услуг</p>
                <p className={styles.price}>
                  <span>от 5 000 ₽</span>/месяц
                </p>
                <button
                  className={styles.buy}
                  onClick={() => {
                    setModalMode("feedback");
                    setIsModalActive(true);
                  }}
                >
                  Оформить тариф
                </button>
                <button
                  className={styles.compare}
                  onClick={() => {
                    setModalMode("compare");
                    setIsModalActive(true);
                  }}
                >
                  Сравнить
                </button>
                <hr />
                <p className={styles.description}>5 простых консультаций</p>
                <ul className={styles.list}>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> Гражданское право
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> Предпринимательское право
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> База шаблонов договоров
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> Договорное право — частично
                  </li>
                  <li>
                    <img src="images/main/rate/gray-arrow.svg" alt="arrow" />
                    Трудовое право
                  </li>
                  <li>
                    <img src="images/main/rate/gray-arrow.svg" alt="arrow" />
                    Судебный процесс
                  </li>
                  <li>
                    <img src="images/main/rate/gray-arrow.svg" alt="arrow" />
                    Налоговое право
                  </li>
                </ul>
                <div
                  className={styles.more}
                  onClick={() => {
                    handleOpenModal(
                      "Тариф «Первый»",
                      "На один месяц",
                      5000,
                      `Оптимальный тариф для предпринимателей, принявших решение вести деятельность самостоятельно. Мы подобрали набор самых популярных услуг, с запросами о которых к нам обращаются индивидуальные предприниматели, и сформировали тариф без лишних затрат
										`,
                      includeService01,
                      false,
                      "",
                      setWhoWillSuit01
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  <button>Подробнее</button>
                </div>
              </div>
            </div>
            <div
              onTouchStart={() => handleMouseDown("light")}
              className={`${styles.column} ${isMouseDown.light ? styles.active : ""}`}
            >
              <div className={styles.item}>
                <h3 className={styles.label}>Старт-UP</h3>
                <p className={styles.labelDescription}>Расширенный набор популярных услуг для своего дела</p>
                <p className={styles.price}>
                  <span>от 8 000 ₽</span>/месяц
                </p>
                <button
                  className={styles.buy}
                  onClick={() => {
                    setModalMode("feedback");
                    setIsModalActive(true);
                  }}
                >
                  Оформить тариф
                </button>
                <button
                  className={styles.compare}
                  onClick={() => {
                    setModalMode("compare");
                    setIsModalActive(true);
                  }}
                >
                  Сравнить
                </button>
                <hr />
                <p className={styles.description}>7 простых и 1 эксклюзивная консультаций</p>
                <ul className={styles.list}>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> Гражданское право
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> Предпринимательское право
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> База шаблонов договоров
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> Договорное право — частично
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" />
                    Трудовое право
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" />
                    Судебный процесс
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" />
                    Налоговое право
                  </li>
                </ul>
                <div
                  className={styles.more}
                  onClick={() => {
                    handleOpenModal(
                      "Тариф «Старт-up»",
                      "На один месяц",
                      8000,
                      `Лучший тариф по мнению наших клиентов, собрал в себе все необходимые услуги для уверенного открытия собственного дела, либо легализации бизнеса. Услуги подобраны на основе многолетней работы, с учетом актуального изменения законодательства, что позволяет не только чувствовать себя в безопасности, но и быстро вести бизнес, без лишней волокиты.
										`,
                      includeService02,
                      false,
                      "",
                      setWhoWillSuit02
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  <button>Подробнее</button>
                </div>
              </div>
            </div>
            <div
              onTouchStart={() => handleMouseDown("minimum")}
              className={`${styles.column} ${isMouseDown.minimum ? styles.active : ""}`}
            >
              <div className={styles.item}>
                <h3 className={styles.label}>Уверенный</h3>
                <p className={styles.labelDescription}>Все самое лучшее в самом большом объеме</p>
                <p className={styles.price}>
                  <span>от 12 000 ₽</span>/месяц
                </p>
                <button
                  className={styles.buy}
                  onClick={() => {
                    setModalMode("feedback");
                    setIsModalActive(true);
                  }}
                >
                  Оформить тариф
                </button>
                <button
                  className={styles.compare}
                  onClick={() => {
                    setModalMode("compare");
                    setIsModalActive(true);
                  }}
                >
                  Сравнить
                </button>
                <hr />
                <p className={styles.description}>5 простых и 3 эксклюзивных консультаций</p>
                <ul className={styles.list}>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> Гражданское право
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> Предпринимательское право
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> База шаблонов договоров
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" /> Договорное право — частично
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" />
                    Трудовое право
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" />
                    Судебный процесс
                  </li>
                  <li>
                    <img src="/images/main/rate/blue-arrow.svg" alt="blue-arrow" />
                    Налоговое право
                  </li>
                </ul>
                <div
                  className={styles.more}
                  onClick={() => {
                    handleOpenModal(
                      "Тариф «Уверенный»",
                      "На один месяц",
                      12000,
                      `Дерзким предпринимателям, не боящимся действовать быстрее всех и задавать тенденции по ведению бизнеса! Все самое лучшее в самом большом объеме
										`,
                      includeService03,
                      false,
                      "",
                      setWhoWillSuit03
                    );
                    setModalMode("more");
                    setIsModalActive(true);
                  }}
                >
                  <button>Подробнее</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Complex;
