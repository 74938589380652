import React from "react";

import styles from "./Modal.module.scss";
import Dropdown from "../Dropdown/Dropdown";
import { Link } from "react-router-dom";
import { IInclude } from "../../pages/Home/Popular/Desktop/PopularDesktop";

interface ModalPropsFeedback {
  isModalActive: boolean;
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  mode: "feedback";
  buyService?: boolean;
}

interface ModalPropsCompare {
  isModalActive: boolean;
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  mode: "compare";
}
interface ModalPropsCompareAccounting {
  isModalActive: boolean;
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  mode: "compareAccounting";
}

interface ModalPropsMore {
  isModalActive: boolean;
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  mode: "more";
  title?: string;
  subTitle?: string;
  price?: number;
  description?: string;
  includeDescription?: IInclude[];
  perMounth?: boolean;
  individualPrice: string;
  isRate?: boolean;
  whoWillSuitDescription?: IInclude[];
  promotion?: boolean;
  complexServices?: IInclude[];
  servicePeriod?: IInclude[];
}

type ModalProps = ModalPropsFeedback | ModalPropsCompare | ModalPropsCompareAccounting | ModalPropsMore;

const DEFAULT_QUESTION = {
  user: "",
  medias: "",
  problem: "",
};

const Modal: React.FC<ModalProps> = (props) => {
  const currentUrl = window.location.pathname;
  const accountingUrl = currentUrl === "/accounting";
  const legalUrl = currentUrl === "/legal";
  const hrUrl = currentUrl === "/legal";

  const feedback = props.mode === "feedback";
  const compare = props.mode === "compare";
  const more = props.mode === "more";
  const compareAccounting = props.mode === "compareAccounting";

  const [question, setQuestion] = React.useState(DEFAULT_QUESTION);
  const [message, setMessage] = React.useState("");
  const [isFormSended, setIsFormSended] = React.useState(false);
  const isAllFieldsEmpty = question.user.length > 0 && question.medias.length > 0 && question.problem.length > 0;

  const [isOrderService, setIsOrderService] = React.useState(false);

  const [isConsultActive, setIsConsultActive] = React.useState(false);

  const [isColumnHovered, setIsColumnHovered] = React.useState({
    first: false,
    second: false,
    third: false,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch("https://lexlegal.ru/api/MainPage/feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: question.user,
          medias: question.medias,
          problem: question.problem,
          problemTheme: more
            ? props.title
            : accountingUrl
            ? "Форма отправлена со страницы бух. услуг"
            : legalUrl
            ? "Форма отправлена со страницы юридических услуг"
            : hrUrl
            ? "Форма отправлена со страницы кадровых услуг"
            : "",
        }),
      });

      if (response.ok) {
        setIsFormSended(true);
        setQuestion(DEFAULT_QUESTION);
        setMessage("");
      } else {
        setMessage("Произошла ошибка при отправке вашей заявки");
      }
    } catch (error) {
      setMessage("Не удалось отправить заявку, попробуйте еще раз");
    }
  };

  React.useEffect(() => {
    if (props.isModalActive) {
      document.body.classList.add("lock");
    } else {
      document.body.classList.remove("lock");
    }

    return () => {
      document.body.classList.remove("lock");
    };
  }, [props.isModalActive]);

  return (
    <div
      onClick={() => {
        if (isOrderService) {
          setIsOrderService(false);
          setIsFormSended(false);
        } else {
          props.setIsModalActive(false);
        }
      }}
      className={`${styles.modal} ${props.isModalActive ? styles.active : ""}`}
    >
      <div className={`${styles.modalContent} ${more ? styles.more : compare || compareAccounting ? styles.compare : ""}`}>
        <div
          onClick={() => {
            if (isOrderService) {
              setIsOrderService(false);
              setIsFormSended(false);
            } else {
              props.setIsModalActive(false);
            }
          }}
          className={styles.closeDesktop}
        >
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 16C0 7.16344 7.16344 0 16 0H32C40.8366 0 48 7.16344 48 16V32C48 40.8366 40.8366 48 32 48H16C7.16344 48 0 40.8366 0 32V16Z"
              fill="#222222"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.0505 16.1802C16.8102 15.9399 16.4206 15.9399 16.1802 16.1802C15.9399 16.4206 15.9399 16.8102 16.1802 17.0505L23.1297 24L16.1802 30.9495C15.9399 31.1898 15.9399 31.5794 16.1802 31.8198C16.4206 32.0601 16.8102 32.0601 17.0505 31.8198L24 24.8703L30.9495 31.8198C31.1898 32.0601 31.5794 32.0601 31.8198 31.8198C32.0601 31.5794 32.0601 31.1898 31.8198 30.9495L24.8703 24L31.8198 17.0505C32.0601 16.8102 32.0601 16.4206 31.8198 16.1802C31.5794 15.9399 31.1898 15.9399 30.9495 16.1802L24 23.1297L17.0505 16.1802Z"
              fill="white"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div
          onClick={() => {
            props.setIsModalActive(false);
            setIsOrderService(false);
            setIsFormSended(false);
          }}
          className={styles.close}
        >
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#222222" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.05053 8.18024C8.8102 7.93992 8.42056 7.93992 8.18024 8.18024C7.93992 8.42056 7.93992 8.8102 8.18024 9.05053L15.1297 16L8.18024 22.9495C7.93992 23.1898 7.93992 23.5794 8.18024 23.8198C8.42056 24.0601 8.8102 24.0601 9.05053 23.8198L16 16.8703L22.9495 23.8198C23.1898 24.0601 23.5794 24.0601 23.8198 23.8198C24.0601 23.5794 24.0601 23.1898 23.8198 22.9495L16.8703 16L23.8198 9.05053C24.0601 8.8102 24.0601 8.42056 23.8198 8.18024C23.5794 7.93992 23.1898 7.93992 22.9495 8.18024L16 15.1297L9.05053 8.18024Z"
              fill="white"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          {feedback && (
            <>
              {isFormSended ? (
                <div className={styles.sended}>
                  <div className={styles.icon}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M24 0C30.3652 0 36.4697 2.52856 40.9706 7.02944C45.4714 11.5303 48 17.6348 48 24C48 30.3652 45.4714 36.4697 40.9706 40.9706C36.4697 45.4714 30.3652 48 24 48C17.6348 48 11.5303 45.4714 7.02944 40.9706C2.52856 36.4697 0 30.3652 0 24C0 17.6348 2.52856 11.5303 7.02944 7.02944C11.5303 2.52856 17.6348 0 24 0ZM21.0103 28.7349L15.6789 23.4C15.4877 23.2089 15.2608 23.0573 15.0111 22.9538C14.7614 22.8504 14.4937 22.7971 14.2234 22.7971C13.9531 22.7971 13.6855 22.8504 13.4358 22.9538C13.186 23.0573 12.9591 23.2089 12.768 23.4C12.382 23.786 12.1651 24.3095 12.1651 24.8554C12.1651 25.4013 12.382 25.9249 12.768 26.3109L19.5566 33.0994C19.7472 33.2915 19.9739 33.444 20.2237 33.548C20.4735 33.6521 20.7414 33.7057 21.012 33.7057C21.2826 33.7057 21.5505 33.6521 21.8003 33.548C22.0501 33.444 22.2768 33.2915 22.4674 33.0994L36.5246 19.0389C36.7183 18.8485 36.8723 18.6217 36.9779 18.3715C37.0835 18.1214 37.1385 17.8527 37.1398 17.5812C37.1411 17.3096 37.0886 17.0405 36.9853 16.7894C36.882 16.5382 36.7301 16.31 36.5382 16.1179C36.3463 15.9257 36.1182 15.7735 35.8672 15.6699C35.6161 15.5664 35.3471 15.5135 35.0755 15.5145C34.804 15.5154 34.5353 15.5701 34.285 15.6754C34.0347 15.7807 33.8077 15.9345 33.6171 16.128L21.0103 28.7349Z"
                        fill="#34C759"
                      />
                    </svg>
                  </div>
                  <h2>Ваша заявка была успешно отправлена</h2>
                  <p>Наша команда специалистов свяжется с вами в ближайшее время</p>
                  <button onClick={() => setIsFormSended(false)}>Отправить еще 1 заявку</button>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <h2>
                    {props.buyService ? (
                      <>
                        Для заказа этой услуги <br />
                        заполните форму ниже
                      </>
                    ) : (
                      <>
                        Предлагаем обсудить <br /> вашу ситуацию
                      </>
                    )}
                  </h2>
                  <div className={styles.text}>
                    <p>Оставьте вашу заявку и наши специалисты свяжутся с вами в ближайшее время</p>
                  </div>
                  <div className={styles.field}>
                    <input
                      value={question.user}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion({ ...question, user: e.target.value })}
                      type="text"
                      placeholder="Как к вам обращаться"
                      required
                    />
                  </div>
                  <div className={message.length === 0 ? `${styles.field}` : `${styles.field} ${styles.incorrect}`}>
                    <input
                      value={question.medias}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion({ ...question, medias: e.target.value })}
                      type="text"
                      placeholder="Ваш Telegram/ ВКонтакте / моб. телефон"
                      required
                    />
                    <p>{message}</p>
                  </div>
                  <div className={styles.field}>
                    <textarea
                      value={question.problem}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setQuestion({ ...question, problem: e.target.value })
                      }
                      placeholder="Кратко опишите ситуацию"
                      required
                    ></textarea>
                  </div>
                  <h3>
                    <span>
                      *Нажимая кнопку отправить, вы соглашаетесь с
                      <Link to="/policy">политикой обработки персональных данных</Link>
                    </span>
                  </h3>
                  <button className={isAllFieldsEmpty ? `${styles.submit} ${styles.fullfilled}` : styles.submit}>
                    Отправить
                  </button>
                </form>
              )}
            </>
          )}
          {isOrderService ? (
            <div className={styles.olderModal}>
              {isFormSended ? (
                <div className={styles.sended}>
                  <div className={styles.icon}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M24 0C30.3652 0 36.4697 2.52856 40.9706 7.02944C45.4714 11.5303 48 17.6348 48 24C48 30.3652 45.4714 36.4697 40.9706 40.9706C36.4697 45.4714 30.3652 48 24 48C17.6348 48 11.5303 45.4714 7.02944 40.9706C2.52856 36.4697 0 30.3652 0 24C0 17.6348 2.52856 11.5303 7.02944 7.02944C11.5303 2.52856 17.6348 0 24 0ZM21.0103 28.7349L15.6789 23.4C15.4877 23.2089 15.2608 23.0573 15.0111 22.9538C14.7614 22.8504 14.4937 22.7971 14.2234 22.7971C13.9531 22.7971 13.6855 22.8504 13.4358 22.9538C13.186 23.0573 12.9591 23.2089 12.768 23.4C12.382 23.786 12.1651 24.3095 12.1651 24.8554C12.1651 25.4013 12.382 25.9249 12.768 26.3109L19.5566 33.0994C19.7472 33.2915 19.9739 33.444 20.2237 33.548C20.4735 33.6521 20.7414 33.7057 21.012 33.7057C21.2826 33.7057 21.5505 33.6521 21.8003 33.548C22.0501 33.444 22.2768 33.2915 22.4674 33.0994L36.5246 19.0389C36.7183 18.8485 36.8723 18.6217 36.9779 18.3715C37.0835 18.1214 37.1385 17.8527 37.1398 17.5812C37.1411 17.3096 37.0886 17.0405 36.9853 16.7894C36.882 16.5382 36.7301 16.31 36.5382 16.1179C36.3463 15.9257 36.1182 15.7735 35.8672 15.6699C35.6161 15.5664 35.3471 15.5135 35.0755 15.5145C34.804 15.5154 34.5353 15.5701 34.285 15.6754C34.0347 15.7807 33.8077 15.9345 33.6171 16.128L21.0103 28.7349Z"
                        fill="#34C759"
                      />
                    </svg>
                  </div>
                  <h2>Ваша заявка была успешно отправлена</h2>
                  <p>Наша команда специалистов свяжется с вами в ближайшее время</p>
                  <button onClick={() => setIsFormSended(false)}>Отправить еще 1 заявку</button>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <h2>
                    Для заказа этой услуги <br />
                    заполните форму ниже
                  </h2>
                  <div className={styles.text}>
                    <p>Оставьте вашу заявку и наши специалисты свяжутся с вами в ближайшее время</p>
                  </div>
                  <div className={styles.field}>
                    <input
                      value={question.user}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion({ ...question, user: e.target.value })}
                      type="text"
                      placeholder="Как к вам обращаться"
                      required
                    />
                  </div>
                  <div className={message.length === 0 ? `${styles.field}` : `${styles.field} ${styles.incorrect}`}>
                    <input
                      value={question.medias}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion({ ...question, medias: e.target.value })}
                      type="text"
                      placeholder="Ваш Telegram/ ВКонтакте / моб. телефон"
                      required
                    />
                    <p>{message}</p>
                  </div>
                  <div className={styles.field}>
                    <textarea
                      value={question.problem}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setQuestion({ ...question, problem: e.target.value })
                      }
                      placeholder="Кратко опишите ситуацию"
                      required
                    ></textarea>
                  </div>
                  <h3>
                    <span>
                      *Нажимая кнопку отправить, вы соглашаетесь с
                      <Link to="/policy" target="_blank">
                        политикой обработки персональных данных
                      </Link>
                    </span>
                  </h3>
                  <button className={isAllFieldsEmpty ? `${styles.submit} ${styles.fullfilled}` : styles.submit}>
                    Отправить
                  </button>
                </form>
              )}
            </div>
          ) : (
            <>
              {more && (
                <div className={styles.moreMode}>
                  <div className={styles.body}>
                    <div className={styles.left}>
                      {props.title === "Акция № 1 «Победителям Студенческий стартап 5-я волна»" ? (
                        <>
                          <img src="/images/main/services/offers.jpg" alt="illustration" />
                        </>
                      ) : props.title === "Акция № 2 «Победителям Студенческий стартап 5-я волна»" ? (
                        <>
                          <img src="/images/main/services/offers02.jpg" alt="illustration" />
                        </>
                      ) : (
                        <>
                          <img src="/images/main/popular/modal01.jpg" alt="illustration" />
                        </>
                      )}
                    </div>
                    <div className={styles.right}>
                      <h2>{props.title}</h2>
                      <h3> {props.subTitle} </h3>
                      {props.individualPrice ? (
                        <p>{props.individualPrice}</p>
                      ) : props.perMounth ? (
                        <p>От {props.price} ₽ /мес</p>
                      ) : (
                        <p>От {props.price} ₽</p>
                      )}
                      {props.title === "Акция № 1 «Победителям Студенческий стартап 5-я волна»" ? (
                        <></>
                      ) : (
                        <>
                          <div className={styles.buy} onClick={() => setIsOrderService(true)}>
                            <button>Заказать услугу</button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.info}>
                    <h2> {props.promotion ? "Описание акции" : props.isRate ? "Описание тарифа:" : "Описание услуги:"}</h2>
                    <p>{props.description}</p>
                  </div>
                  {props.complexServices && (
                    <ul className={styles.includes}>
                      <h2>Комплексное обслуживание включает в себя следующие сферы услуг:</h2>

                      {props.complexServices?.map((item) => (
                        <li key={item.id}>- {item.text}</li>
                      ))}
                    </ul>
                  )}
                  {props.isRate && (
                    <ul className={styles.includes}>
                      <h2>{props.promotion ? "Кому пойдет акция" : "Кому пойдет тариф:"}</h2>

                      {props.whoWillSuitDescription?.map((item) => (
                        <li key={item.id}>- {item.text}</li>
                      ))}
                    </ul>
                  )}
                  <ul className={styles.includes}>
                    <h2>
                      {props.promotion ? "Правила оплаты:" : props.isRate ? "Что входит в тариф:" : "Что входит в данную услугу"}
                    </h2>

                    {props.includeDescription?.map((item) => (
                      <li key={item.id}>- {item.text}</li>
                    ))}
                  </ul>
                  {props.servicePeriod && (
                    <ul className={styles.includes}>
                      <h2>Срок обслуживания:</h2>

                      {props.servicePeriod?.map((item) => (
                        <li key={item.id}>- {item.text}</li>
                      ))}
                    </ul>
                  )}
                  <p className={styles.moreInfo}>
                    * полный перечень услуг, а также объем и порядок их оказания определяется в соответствии с условиями договора.
                    Предложение не является публичной офертой.
                  </p>
                  <span style={{ opacity: 0 }}>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nihil iusto sit, perferendis veniam nisi ad sunt
                    esse suscipit quasi consequatur ex, excepturi dolor? Dicta similique repellat quam veritatis architecto nulla.
                  </span>
                </div>
              )}
            </>
          )}
          {compare && (
            <>
              <table>
                <tr className={styles.tableHeader}>
                  <th>Таблица сравнения тарифов</th>
                  <th
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    Первый
                  </th>
                  <th
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    Старт-UP
                  </th>
                  <th
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    Уверенный
                  </th>
                </tr>
                <tr>
                  <td>
                    Консультации по правовым вопросам бизнеса <br />
                    <span>
                      * Простая — путем направления ответа в мессенджере <br /> * Эксклюзивная — групповое обсуждение со
                      специалистами в формате видео-звонки с направлением последующего заключения на фирменном бланке
                    </span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    Простая 5 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    Эксклюзивная 1 шт. Простая 7 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    Эксклюзивная 3 шт. Простая 5 шт.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Гражданское право</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Предпринимательское право</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Трудовое право</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Судебный процесс</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Налоговое право</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Предоставление базы шаблонных договоров</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>Договорное право</td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  ></td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  ></td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  ></td>
                </tr>
                <tr>
                  <td>
                    <span>Разработка индивидуальных договоров</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    2 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    5 шт.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Экспертиза договора</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    1 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    2 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    5 шт.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Подготовка дс / актов / протоколов</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    1 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    2 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    5 шт.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Участие в переговорах с контрагентом при согласовании договора</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Проверка закрывающих документов</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>Трудовое право</td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  ></td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  ></td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  ></td>
                </tr>
                <tr>
                  <td>
                    <span>Разработка трудового договора по условиям заказчика</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    2 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    5 шт.
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Подготовка документов для сотрудников на удаленной работе</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="none" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Аудит и актуализация политики компании по работе с персональными данными</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="none" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Помощь с увольнение сотрудников без последствий</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="none" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Оценка судебной перспективы разрешения спора</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="none" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Работа с документами на английском / китайском / японском языках</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="none" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/blue-arrow.svg" alt="none" />
                  </td>
                </tr>
                <tr>
                  <td>Стоимость в месяц :</td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    5 000 ₽
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    8 000 ₽
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    12 000 ₽
                  </td>
                </tr>
              </table>
              <div className={styles.table}>
                <div className={styles.column}>
                  <h2 className={styles.title}>Таблица сравнения тарифов</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <p>Первый</p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <p>Старт-UP</p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <p>Уверенный</p>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>
                    Консультации по правовым вопросам бизнеса
                    <img onClick={() => setIsConsultActive(!isConsultActive)} src="/images/modal/info.svg" alt="info" />
                  </h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <p>Простая 5 шт.</p>
                    </div>
                    <div className={`${styles.divider} ${styles.bigger}`} />
                    <div className={styles.item}>
                      <p>
                        Эксклюзивная 1 шт. <br /> Простая 7 шт.
                      </p>
                    </div>
                    <div className={`${styles.divider} ${styles.bigger}`} />
                    <div className={styles.item}>
                      <p>
                        Эксклюзивная 3 шт. <br /> Простая 5 шт.
                      </p>
                    </div>
                  </div>
                  {isConsultActive && (
                    <div className={styles.consultModal}>
                      <div className={styles.closeIcon} onClick={() => setIsConsultActive(false)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.05053 4.18024C4.8102 3.93992 4.42056 3.93992 4.18024 4.18024C3.93992 4.42056 3.93992 4.8102 4.18024 5.05053L11.1297 12L4.18024 18.9495C3.93992 19.1898 3.93992 19.5794 4.18024 19.8198C4.42056 20.0601 4.8102 20.0601 5.05053 19.8198L12 12.8703L18.9495 19.8198C19.1898 20.0601 19.5794 20.0601 19.8198 19.8198C20.0601 19.5794 20.0601 19.1898 19.8198 18.9495L12.8703 12L19.8198 5.05053C20.0601 4.8102 20.0601 4.42056 19.8198 4.18024C19.5794 3.93992 19.1898 3.93992 18.9495 4.18024L12 11.1297L5.05053 4.18024Z"
                            fill="#E4E6E8"
                            stroke="#E4E6E8"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                      <h2 className={styles.consultTitle}>Консультации по правовым вопросам бизнеса</h2>
                      <p className={styles.consultText}>* Простая — путем направления ответа в мессенджере </p>
                      <p className={styles.consultText}>
                        * Эксклюзивная — групповое обсуждение со специалистами в формате видео-звонки с направлением последующего
                        заключения на фирменном бланке
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Гражданское право</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Предпринимательское право</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Трудовое право</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Налоговое право</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Предоставление базы шаблонных договоров</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.name}>Договорное право</h2>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Разработка индивидуальных договоров</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>2 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>5 шт.</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Экспертиза договора</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>1 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>3 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>5 шт.</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Подготовка дс / актов / протоколов</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>1 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>3 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>5 шт.</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Участие в переговорах с контрагентом при согласовании договора</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.6704 0.9906C20.0409 1.37735 20.0409 1.9873 19.6704 2.37405L7.34088 15.2461C6.94717 15.6571 6.29027 15.6571 5.89656 15.2461L0.995582 10.1295C0.625129 9.74271 0.625129 9.13276 0.995583 8.74601L1.22316 8.50842C1.61654 8.09773 2.27275 8.09733 2.66663 8.50753L5.89687 11.8716C6.29062 12.2817 6.94657 12.2815 7.34001 11.8711L17.9994 0.753237C18.3932 0.342526 19.0498 0.342679 19.4434 0.753577L19.6704 0.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Проверка закрывающих документов</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.name}>Трудовое право</h2>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Разработка трудового договора по условиям заказчика</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>2 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>3 шт.</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Подготовка документов для сотрудников на удаленной работе</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Аудит и актуализация политики компании по работе с персональными данными</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Помощь с увольнением сотрудников без последствий</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Оценка судебной перспективы разрешения спора</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Работа с документами на английском / китайском / японском языках</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M22.0039 4.9906C22.3744 5.37735 22.3744 5.9873 22.0039 6.37405L9.67438 19.2461C9.28067 19.6571 8.62377 19.6571 8.23006 19.2461L3.32908 14.1295C2.95862 13.7427 2.95863 13.1328 3.32908 12.746L3.55666 12.5084C3.95003 12.0977 4.60625 12.0973 5.00013 12.5075L8.23036 15.8716C8.62411 16.2817 9.28007 16.2815 9.67351 15.8711L20.3329 4.75324C20.7267 4.34253 21.3833 4.34268 21.7769 4.75358L22.0039 4.9906Z"
                          fill="#60B3FF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Стоимость в месяц</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>5 000 ₽</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>8 000 ₽</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>12 000 ₽</div>
                  </div>
                </div>
              </div>
            </>
          )}
          {compareAccounting && (
            <>
              <table>
                <tr className={styles.tableHeader}>
                  <th>Таблица сравнения тарифов</th>
                  <th
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    Лайт
                  </th>
                  <th
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    Оптимальный
                  </th>
                  <th
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    Продвинутый
                  </th>
                </tr>
                <tr>
                  <td>Критерии обслуживания</td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  ></td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  ></td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  ></td>
                </tr>
                <tr>
                  <td>
                    <span>Количество расчетных счетов</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    1
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    2
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    2
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Количество касс</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    0
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    1
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    2
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Количество сотрудников</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    1
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    3
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    5
                  </td>
                </tr>
                <tr>
                  <td>
                    Подготовка первичных документов <br /> <br />
                    <span>( прим. см. перечень первичных документов )</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    до 10 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    до 20 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    до 50 шт.
                  </td>
                </tr>
                <tr>
                  <td>
                    Подготовка отчетности в СФР, ФНС, Росстат в формате ЭДО <br /> <br />
                    <span>( прим. см. примечания к таблице п.2 )</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    Бухгалтерский и налоговый учет текущих операций <br /> <br />
                    <span>( прим. см. перечень первичных документов )</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    до 5 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    до 10 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    до 20 шт.
                  </td>
                </tr>
                <tr>
                  <td>Экспресс-проверка базы 1С ( версия 8.3 ) и сданной отчетности на соответствие за прошлые периоды</td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>
                    Консультация эксперта <br />
                    <span>* Простая - путем направления ответа в мессенджере</span> <br /> <br />
                    <span>
                      * Эксклюзивная - групповое обсуждение со специалистами в формате видео-звонки с направлением последующего
                      заключения на фирменном бланке
                    </span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    Простая 1 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    Эксклюзивная 1 шт. Простая 3 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    Эксклюзивная 3 шт. Простая 10 шт.
                  </td>
                </tr>
                <tr>
                  <td>Учет документов</td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    до 2 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    до 20 шт.
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    до 70 шт.
                  </td>
                </tr>
                <tr>
                  <td>Помощь в регистрации и направлении отчетности через сервисы nalog.ru, gosusligu.ru</td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/none.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>Контроль сроков и размеров выплат з/п и вознаграждений сотрудникам</td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    <img src="/images/modal/orange-arrow.svg" alt="arrow" />
                  </td>
                </tr>
                <tr>
                  <td>Стоимость в месяц</td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  ></td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  ></td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  ></td>
                </tr>
                <tr>
                  <td>
                    <span>ОСН</span>{" "}
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    6 500 ₽
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    8 500 ₽
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    12 500 ₽
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>УСН (доходы)</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    4 500 ₽
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    6 500 ₽
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    10 500 ₽
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>УСН (доходы - расходы)</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    5 000 ₽
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    7 500 ₽
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    11 500 ₽
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>ПСН</span>
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: true, second: false, third: false });
                    }}
                    className={isColumnHovered.first ? styles.hovered : ""}
                  >
                    6 500 ₽
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: true, third: false });
                    }}
                    className={isColumnHovered.second ? styles.hovered : ""}
                  >
                    8 000 ₽
                  </td>
                  <td
                    onMouseEnter={() => {
                      setIsColumnHovered({ ...isColumnHovered, first: false, second: false, third: true });
                    }}
                    className={isColumnHovered.third ? styles.hovered : ""}
                  >
                    12 000 ₽
                  </td>
                </tr>
              </table>
              <div className={styles.dropdowns}>
                <Dropdown
                  modalSpecial={true}
                  title="Примечание к таблице сравнения тарифов"
                  text={
                    <>
                      1. В случае, если налоговый учет велся в любой иной программе кроме 1С версии 8.3, либо при
                      экспресс-проверке будут выявлены несоответствия, вам будет необходимо пройти экспресс <br /> 2. Сдача
                      отчетности не входит в стоимость услуг. Отчетность сдается через сервис-партнер СБИС, путем подписания
                      документов УКЭП. Доплата не требуется; <br /> 3. Минимальный срок обслуживания 12 месяцев. Возможно
                      подписание договора на 18 и 24 месяца, в данном случае предоставляется скидка от общей стоимости 6% и 12%
                      соответственно. <br /> 4. Превышение лимитов по тарифу оплачивается дополнительно, в соответствии с таблицей
                      «Перечень дополнительных услуг».
                    </>
                  }
                />
                <Dropdown
                  title="Перечень первичных документов"
                  options={[
                    { id: 0, title: "счет на оплату;" },
                    { id: 1, title: "счет-фактура;" },
                    { id: 2, title: "акт выполненных работ;" },
                    { id: 3, title: "накладная;" },
                    { id: 4, title: "приходный кассовый ордер;" },
                    { id: 5, title: "расходный кассовый ордер;" },
                    { id: 6, title: "акт ОС;" },
                    { id: 7, title: "авансовый очет;" },
                    { id: 8, title: "акт взаимозачета;" },
                    { id: 9, title: "акт сверки расчётов;" },
                    { id: 10, title: "требование-накладная на списание материалов (перемещение);" },
                  ]}
                  modalSpecial={true}
                />
                <Dropdown
                  title="Перечень учитываемых операций"
                  text={
                    <>
                      <br /> 1.5.1. Операции с основными средствами (поступление, ввод в эксплуатацию, перемещение, начисление
                      амортизации, реализация, модернизация, прочее выбытие, в том числе арендованное). <br /> 1.5.2. Операции с
                      доходными вложениями в материальные ценности (оприходование, перемещение, реализация, прочее выбытие).
                      <br /> 1.5.3. Операции с нематериальными активами (поступление, ввод в эксплуатацию, перемещение, начисление
                      амортизации, реализация, модернизация, прочее выбытие). <br /> 1.5.4. Операции с сырьем и материалами
                      (поступление, перемещение, реализация, прочее выбытие, учет горюче-смазочных материалов (далее - «ГСМ»).
                      <br /> 1.5.5. Учет производства (операции по выпуску продукции, списание брака, формирование себестоимости).
                      <br /> 1.5.6. Операции с товарами (поступление, перемещение, реализация, комплектация, передача в
                      переработку, списание брака, формирование себестоимости, реализация, реализация ранее отгруженных товаров, в
                      том числе импортированных/экспортированных товаров). <br /> 1.5.7. Операции с кассой (поступление, расход,
                      учет денежных документов). <br /> 1.5.8. Операции с банком (подготовка платежных поручений в печатном
                      формате, в формате TXT, проведение банковской выписки, конвертация). <br /> 1.5.9. Обмен банковскими
                      документами между программой 1С и клиент-банком без прямого доступа к клиент-банку Заказчика, при условии
                      предоставления Заказчиком необходимых данных для осуществления подключения сервиса. <br /> 1.5.10. Операции
                      с онлайн-кассой (проведение отчета о розничных продажах, проведение операций по платежным картам, проведение
                      операций по поступлению наличных денежных средств, сверка фискальных документов). <br /> 1.5.11. Операции по
                      агентским договорам (проведение отчета агента, проведение счет-фактур на агентское вознаграждение,
                      перевыставление счет-фактур). <br /> 1.5.12. Операции по учету доходов, полученных через маркетплейсы, в том
                      числе на зарубежных площадках. <br /> 1.5.13. Операции по авансовым отчетам (отражение авансового отчета,
                      операции по путевым листам, отражение командировочных расходов, включая начисление суточных в соответствии с
                      действующим MBA_agreement_ver.10 законодательством и локальными нормативными актами Заказчика при их
                      наличии, формирование авансового отчета на подпись подотчетному лицу, подготовка шаблонов документов по
                      представительским расходам). <br /> 1.5.14. Операции по финансовым вложениям (учет займов выданных,
                      начисление процентов по займам выданным). <br /> 1.5.15. Учет операций по лизингу <br /> 1.5.16. Операции по
                      приобретенным правам (факторинг). <br /> 1.5.17. Операции с поставщиками в части полученных товаров
                      (формирование актов сверок по запросу Заказчика; проверка актов сверок по запросу Заказчика). <br /> 1.5.18.
                      Операции с заказчиками в части выполненных работ и оказанных услуг (формирование актов сверок по запросу
                      Заказчика).
                      <br /> 1.5.19. Операции по расчетам с учредителями (подготовка промежуточного баланса, начисление
                      дивидендов; формирование добавочного капитала, отражение в учете изменений в составе учредителей). <br />{" "}
                      1.5.20. Разработка типовой формы учетной политики. <br /> 1.5.21. Подготовка реестра недостающих документов
                      от контрагентов Заказчику. <br /> 1.5.22. Операции с кредитами и займами полученными (начисление процентов,
                      нормирование процентов для налогового учета, капитализация процентов). <br /> 1.5.23. Операции по налогу на
                      добавленную стоимость (формирование счет-фактуры на аванс полученный, отражение счет-фактуры по налоговому
                      агенту, формирование книги покупок и книги продаж). <br /> 1.5.24. Операции по расчету налога при УСН
                      (расчет авансовых платежей, формирование книги доходов и расходов). <br /> 1.5.25. Операции по расчету
                      платежа при применении патентной системы налогообложения.
                      <br /> 1.5.26. Расчет фиксированных страховых взносов для ИП. <br /> 1.5.27. Ведение раздельного налогового
                      учета при совмещении систем налогообложения. <br /> 1.5.28. Учет доходов, ведение книги доходов при
                      применении патентной системы налогообложения. <br /> 1.5.29. Учет поступления целевых взносов для
                      некоммерческих организаций. <br /> 1.5.30. Предоставление данных для формирования пакета документов на
                      получение субсидий, получаемых из государственного бюджета. <br /> 1.5.31. Сверка с ИФНС, СФР за период
                      текущего сопровождения (запрос и анализ актов сверки и выписок по расчету с бюджетом, справок о исполнении
                      обязанности по уплате налогов и взносов, справок об отсутствии задолженности). Предоставляется при
                      подключении к Личному кабинету СБИС.
                      <br /> 1.5.32. Подготовка ответов на требование ФНС, СФР за период текущего сопровождения. <br /> 1.5.33.
                      Подготовка обязательных типовых форм отчетности в контролирующие органы для Заказчиков, которые обязаны их
                      сдавать в соответствии с действующим законодательством: - Декларация по налогу на добавленную стоимость; -
                      Декларация по налогу на прибыль; - Декларация по налогу на имущество;- - Декларация по налогу, уплачиваемому
                      в связи с применением упрощенной системы налогообложения (Декларация по УСН); - Книгу учета доходов; - Книгу
                      учета доходов и расходов; - Декларация по форме 3-НДФЛ для ИП - Книга учета доходов и расходов для ИП на
                      ОСНО - Декларация по косвенным налогам; - Упрощенный бухгалтерский баланс; - Упрощенный отчет о финансовых
                      результатах; - Единая (упрощенная) налоговая декларация; - Журнал учета счетов-фактур; - Налоговый расчет о
                      суммах, выплаченных иностранным организациям доходов и удержанных налогов; - Уведомление об исчисленных
                      суммах налогов, авансовых платежей по налогам, сборов, страховых взносов; - Формы Росстат (входят в тариф
                      «Премиальный» и «Премиальный+»). <br /> 1.5.34. Предоставление данных о финансовых результатах, оборотах по
                      счетам, оборотах по субконто, общих суммах выплат или перечислений с расчетных счетов или кассы, сумме
                      уплаченных налогов и/или сборов, суммах произведенных расходов или полученных доходах и т.п., в виде
                      стандартных отчетов, предусмотренных актуальной конфигурацией программного обеспечения 1С: Предприятие 8.3.
                      MBA_agreement_ver.10 <br /> 1.5.35. Расчет ежемесячной суммы Единого налогового платежа, подготовка
                      платёжного поручения на пополнение Единого налогового счета.
                      <span style={{ opacity: 0 }}>lorem lorem lorem lorem lorem</span>
                    </>
                  }
                  modalSpecial={true}
                />
                <Dropdown title="Таблица перечня дополнительных услуг" modalTable={true} />
              </div>
              <div className={styles.table}>
                <div className={styles.column}>
                  <h2 className={styles.title}>Таблица сравнения тарифов</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <p>Лайт</p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <p>Оптимальный</p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <p>Продвинутый</p>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.name}>Критерии обслуживания</h2>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Количество расчетных счетов</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <p>1</p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <p>2</p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <p>2</p>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Количество касс</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>0</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>1</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>2</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>Количество сотрудников</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>1</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>3</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>5</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>
                    Подготовка первичных документов{" "}
                    <img onClick={() => setIsConsultActive(!isConsultActive)} src="/images/modal/info.svg" alt="info" />
                  </h2>
                  <div className={styles.row}>
                    <div className={styles.item}>до 10 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>до 20 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>до 50 шт.</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>
                    Подготовка отчетности в СФР, ФНС, Росстат в формате ЭДО
                    <img onClick={() => setIsConsultActive(!isConsultActive)} src="/images/modal/info.svg" alt="info" />
                  </h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.0039 0.9906C19.3744 1.37735 19.3744 1.9873 19.0039 2.37405L6.67438 15.2461C6.28067 15.6571 5.62377 15.6571 5.23006 15.2461L0.329078 10.1295C-0.0413751 9.74271 -0.0413747 9.13276 0.329079 8.74601L0.556655 8.50842C0.950032 8.09773 1.60625 8.09733 2.00013 8.50753L5.23036 11.8716C5.62411 12.2817 6.28007 12.2815 6.67351 11.8711L17.3329 0.753237C17.7267 0.342526 18.3833 0.342679 18.7769 0.753577L19.0039 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.0039 0.9906C19.3744 1.37735 19.3744 1.9873 19.0039 2.37405L6.67438 15.2461C6.28067 15.6571 5.62377 15.6571 5.23006 15.2461L0.329078 10.1295C-0.0413751 9.74271 -0.0413747 9.13276 0.329079 8.74601L0.556655 8.50842C0.950032 8.09773 1.60625 8.09733 2.00013 8.50753L5.23036 11.8716C5.62411 12.2817 6.28007 12.2815 6.67351 11.8711L17.3329 0.753237C17.7267 0.342526 18.3833 0.342679 18.7769 0.753577L19.0039 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.0039 0.9906C19.3744 1.37735 19.3744 1.9873 19.0039 2.37405L6.67438 15.2461C6.28067 15.6571 5.62377 15.6571 5.23006 15.2461L0.329078 10.1295C-0.0413751 9.74271 -0.0413747 9.13276 0.329079 8.74601L0.556655 8.50842C0.950032 8.09773 1.60625 8.09733 2.00013 8.50753L5.23036 11.8716C5.62411 12.2817 6.28007 12.2815 6.67351 11.8711L17.3329 0.753237C17.7267 0.342526 18.3833 0.342679 18.7769 0.753577L19.0039 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>
                    Бухгалтерский и налоговый учет текущих операций
                    <img onClick={() => setIsConsultActive(!isConsultActive)} src="/images/modal/info.svg" alt="info" />
                  </h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.0039 0.9906C19.3744 1.37735 19.3744 1.9873 19.0039 2.37405L6.67438 15.2461C6.28067 15.6571 5.62377 15.6571 5.23006 15.2461L0.329078 10.1295C-0.0413751 9.74271 -0.0413747 9.13276 0.329079 8.74601L0.556655 8.50842C0.950032 8.09773 1.60625 8.09733 2.00013 8.50753L5.23036 11.8716C5.62411 12.2817 6.28007 12.2815 6.67351 11.8711L17.3329 0.753237C17.7267 0.342526 18.3833 0.342679 18.7769 0.753577L19.0039 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.0039 0.9906C19.3744 1.37735 19.3744 1.9873 19.0039 2.37405L6.67438 15.2461C6.28067 15.6571 5.62377 15.6571 5.23006 15.2461L0.329078 10.1295C-0.0413751 9.74271 -0.0413747 9.13276 0.329079 8.74601L0.556655 8.50842C0.950032 8.09773 1.60625 8.09733 2.00013 8.50753L5.23036 11.8716C5.62411 12.2817 6.28007 12.2815 6.67351 11.8711L17.3329 0.753237C17.7267 0.342526 18.3833 0.342679 18.7769 0.753577L19.0039 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.0039 0.9906C19.3744 1.37735 19.3744 1.9873 19.0039 2.37405L6.67438 15.2461C6.28067 15.6571 5.62377 15.6571 5.23006 15.2461L0.329078 10.1295C-0.0413751 9.74271 -0.0413747 9.13276 0.329079 8.74601L0.556655 8.50842C0.950032 8.09773 1.60625 8.09733 2.00013 8.50753L5.23036 11.8716C5.62411 12.2817 6.28007 12.2815 6.67351 11.8711L17.3329 0.753237C17.7267 0.342526 18.3833 0.342679 18.7769 0.753577L19.0039 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.name}>
                    Экспресс-проверка базы 1С ( версия 8.3 ) и сданной отчетности на соответствие за прошлые периоды
                  </h2>
                  <div className={styles.row}>
                    <div className={styles.item}>1 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>1 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>1 шт.</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>
                    Консультация эксперта
                    <img onClick={() => setIsConsultActive(!isConsultActive)} src="/images/modal/info.svg" alt="info" />
                  </h2>
                  <div className={styles.row}>
                    <div className={styles.item}>Простая 1 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>Эксклюзивная 1 шт. Простая 3 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>Эксклюзивная 3 шт. Простая 10 шт.</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.name}>Учет документов</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>до 2 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>до 20 шт.</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>до 70 шт.</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.name}>
                    Помощь в регистрации и направлении отчетности через сервисы nalog.ru, gosusligu.ru
                  </h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.71703 4.18024C5.47671 3.93992 5.08707 3.93992 4.84675 4.18024C4.60642 4.42056 4.60642 4.8102 4.84675 5.05053L11.7962 12L4.84675 18.9495C4.60642 19.1898 4.60642 19.5794 4.84675 19.8198C5.08707 20.0601 5.47671 20.0601 5.71703 19.8198L12.6665 12.8703L19.616 19.8198C19.8563 20.0601 20.2459 20.0601 20.4863 19.8198C20.7266 19.5794 20.7266 19.1898 20.4863 18.9495L13.5368 12L20.4863 5.05053C20.7266 4.8102 20.7266 4.42056 20.4863 4.18024C20.2459 3.93992 19.8563 3.93992 19.616 4.18024L12.6665 11.1297L5.71703 4.18024Z"
                          fill="#E4E6E8"
                          stroke="#E4E6E8"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.3374 0.9906C19.7079 1.37735 19.7079 1.9873 19.3374 2.37405L7.00787 15.2461C6.61416 15.6571 5.95726 15.6571 5.56355 15.2461L0.662574 10.1295C0.292121 9.74271 0.292121 9.13276 0.662575 8.74601L0.890152 8.50842C1.28353 8.09773 1.93975 8.09733 2.33363 8.50753L5.56386 11.8716C5.95761 12.2817 6.61356 12.2815 7.00701 11.8711L17.6664 0.753237C18.0602 0.342526 18.7168 0.342679 19.1104 0.753577L19.3374 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.3374 0.9906C19.7079 1.37735 19.7079 1.9873 19.3374 2.37405L7.00787 15.2461C6.61416 15.6571 5.95726 15.6571 5.56355 15.2461L0.662574 10.1295C0.292121 9.74271 0.292121 9.13276 0.662575 8.74601L0.890152 8.50842C1.28353 8.09773 1.93975 8.09733 2.33363 8.50753L5.56386 11.8716C5.95761 12.2817 6.61356 12.2815 7.00701 11.8711L17.6664 0.753237C18.0602 0.342526 18.7168 0.342679 19.1104 0.753577L19.3374 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.name}>Контроль сроков и размеров выплат з/п и вознаграждений сотрудникам </h2>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.0039 0.9906C19.3744 1.37735 19.3744 1.9873 19.0039 2.37405L6.67438 15.2461C6.28067 15.6571 5.62377 15.6571 5.23006 15.2461L0.329078 10.1295C-0.0413751 9.74271 -0.0413747 9.13276 0.329079 8.74601L0.556655 8.50842C0.950032 8.09773 1.60625 8.09733 2.00013 8.50753L5.23036 11.8716C5.62411 12.2817 6.28007 12.2815 6.67351 11.8711L17.3329 0.753237C17.7267 0.342526 18.3833 0.342679 18.7769 0.753577L19.0039 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.0039 0.9906C19.3744 1.37735 19.3744 1.9873 19.0039 2.37405L6.67438 15.2461C6.28067 15.6571 5.62377 15.6571 5.23006 15.2461L0.329078 10.1295C-0.0413751 9.74271 -0.0413747 9.13276 0.329079 8.74601L0.556655 8.50842C0.950032 8.09773 1.60625 8.09733 2.00013 8.50753L5.23036 11.8716C5.62411 12.2817 6.28007 12.2815 6.67351 11.8711L17.3329 0.753237C17.7267 0.342526 18.3833 0.342679 18.7769 0.753577L19.0039 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.item}>
                      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.0039 0.9906C19.3744 1.37735 19.3744 1.9873 19.0039 2.37405L6.67438 15.2461C6.28067 15.6571 5.62377 15.6571 5.23006 15.2461L0.329078 10.1295C-0.0413751 9.74271 -0.0413747 9.13276 0.329079 8.74601L0.556655 8.50842C0.950032 8.09773 1.60625 8.09733 2.00013 8.50753L5.23036 11.8716C5.62411 12.2817 6.28007 12.2815 6.67351 11.8711L17.3329 0.753237C17.7267 0.342526 18.3833 0.342679 18.7769 0.753577L19.0039 0.9906Z"
                          fill="#FFB74A"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.name}>Стоимость в месяц</h2>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>ОСН</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>6 500 ₽</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>8 500 ₽</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>12 500 ₽</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>УСН (доходы)</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>4 500 ₽</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>6 500 ₽</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>10 500 ₽</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>УСН (доходы - расходы)</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>5 000 ₽</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>7 500 ₽</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>11 500 ₽</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <h2 className={styles.title}>ПСН</h2>
                  <div className={styles.row}>
                    <div className={styles.item}>6 500 ₽</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>8 000 ₽</div>
                    <div className={styles.divider} />
                    <div className={styles.item}>12 000 ₽</div>
                  </div>
                </div>
                <div className={styles.column}>
                  <Dropdown
                    title="Примечание к таблице сравнения тарифов"
                    text={
                      <>
                        1. В случае, если налоговый учет велся в любой иной программе кроме 1С версии 8.3, либо при
                        экспресс-проверке будут выявлены несоответствия, вам будет необходимо пройти экспресс <br /> 2. Сдача
                        отчетности не входит в стоимость услуг. Отчетность сдается через сервис-партнер СБИС, путем подписания
                        документов УКЭП. Доплата не требуется; <br /> 3. Минимальный срок обслуживания 12 месяцев. Возможно
                        подписание договора на 18 и 24 месяца, в данном случае предоставляется скидка от общей стоимости 6% и 12%
                        соответственно. <br /> 4. Превышение лимитов по тарифу оплачивается дополнительно, в соответствии с
                        таблицей «Перечень дополнительных услуг».
                      </>
                    }
                    modalWhite={true}
                  />
                </div>
                <div className={`${styles.column} ${styles.dropdownOpt}`}>
                  <Dropdown
                    title="Перечень первичных документов"
                    options={[
                      { id: 0, title: "счет на оплату;" },
                      { id: 1, title: "счет-фактура;" },
                      { id: 2, title: "акт выполненных работ;" },
                      { id: 3, title: "накладная;" },
                      { id: 4, title: "приходный кассовый ордер;" },
                      { id: 5, title: "расходный кассовый ордер;" },
                      { id: 6, title: "акт ОС;" },
                      { id: 7, title: "авансовый очет;" },
                      { id: 8, title: "акт взаимозачета;" },
                      { id: 9, title: "акт сверки расчётов;" },
                      { id: 10, title: "требование-накладная на списание материалов (перемещение);" },
                    ]}
                    modalWhite={true}
                  />
                </div>
                <div className={styles.column}>
                  <Dropdown
                    title="Перечень учитываемых операций"
                    text={
                      <>
                        <br /> 1.5.1. Операции с основными средствами (поступление, ввод в эксплуатацию, перемещение, начисление
                        амортизации, реализация, модернизация, прочее выбытие, в том числе арендованное). <br /> 1.5.2. Операции с
                        доходными вложениями в материальные ценности (оприходование, перемещение, реализация, прочее выбытие).
                        <br /> 1.5.3. Операции с нематериальными активами (поступление, ввод в эксплуатацию, перемещение,
                        начисление амортизации, реализация, модернизация, прочее выбытие). <br /> 1.5.4. Операции с сырьем и
                        материалами (поступление, перемещение, реализация, прочее выбытие, учет горюче-смазочных материалов (далее
                        - «ГСМ»).
                        <br /> 1.5.5. Учет производства (операции по выпуску продукции, списание брака, формирование
                        себестоимости).
                        <br /> 1.5.6. Операции с товарами (поступление, перемещение, реализация, комплектация, передача в
                        переработку, списание брака, формирование себестоимости, реализация, реализация ранее отгруженных товаров,
                        в том числе импортированных/экспортированных товаров). <br /> 1.5.7. Операции с кассой (поступление,
                        расход, учет денежных документов). <br /> 1.5.8. Операции с банком (подготовка платежных поручений в
                        печатном формате, в формате TXT, проведение банковской выписки, конвертация). <br /> 1.5.9. Обмен
                        банковскими документами между программой 1С и клиент-банком без прямого доступа к клиент-банку Заказчика,
                        при условии предоставления Заказчиком необходимых данных для осуществления подключения сервиса. <br />{" "}
                        1.5.10. Операции с онлайн-кассой (проведение отчета о розничных продажах, проведение операций по платежным
                        картам, проведение операций по поступлению наличных денежных средств, сверка фискальных документов).{" "}
                        <br /> 1.5.11. Операции по агентским договорам (проведение отчета агента, проведение счет-фактур на
                        агентское вознаграждение, перевыставление счет-фактур). <br /> 1.5.12. Операции по учету доходов,
                        полученных через маркетплейсы, в том числе на зарубежных площадках. <br /> 1.5.13. Операции по авансовым
                        отчетам (отражение авансового отчета, операции по путевым листам, отражение командировочных расходов,
                        включая начисление суточных в соответствии с действующим MBA_agreement_ver.10 законодательством и
                        локальными нормативными актами Заказчика при их наличии, формирование авансового отчета на подпись
                        подотчетному лицу, подготовка шаблонов документов по представительским расходам). <br /> 1.5.14. Операции
                        по финансовым вложениям (учет займов выданных, начисление процентов по займам выданным). <br /> 1.5.15.
                        Учет операций по лизингу <br /> 1.5.16. Операции по приобретенным правам (факторинг). <br /> 1.5.17.
                        Операции с поставщиками в части полученных товаров (формирование актов сверок по запросу Заказчика;
                        проверка актов сверок по запросу Заказчика). <br /> 1.5.18. Операции с заказчиками в части выполненных
                        работ и оказанных услуг (формирование актов сверок по запросу Заказчика).
                        <br /> 1.5.19. Операции по расчетам с учредителями (подготовка промежуточного баланса, начисление
                        дивидендов; формирование добавочного капитала, отражение в учете изменений в составе учредителей). <br />{" "}
                        1.5.20. Разработка типовой формы учетной политики. <br /> 1.5.21. Подготовка реестра недостающих
                        документов от контрагентов Заказчику. <br /> 1.5.22. Операции с кредитами и займами полученными
                        (начисление процентов, нормирование процентов для налогового учета, капитализация процентов). <br />{" "}
                        1.5.23. Операции по налогу на добавленную стоимость (формирование счет-фактуры на аванс полученный,
                        отражение счет-фактуры по налоговому агенту, формирование книги покупок и книги продаж). <br /> 1.5.24.
                        Операции по расчету налога при УСН (расчет авансовых платежей, формирование книги доходов и расходов).{" "}
                        <br /> 1.5.25. Операции по расчету платежа при применении патентной системы налогообложения.
                        <br /> 1.5.26. Расчет фиксированных страховых взносов для ИП. <br /> 1.5.27. Ведение раздельного
                        налогового учета при совмещении систем налогообложения. <br /> 1.5.28. Учет доходов, ведение книги доходов
                        при применении патентной системы налогообложения. <br /> 1.5.29. Учет поступления целевых взносов для
                        некоммерческих организаций. <br /> 1.5.30. Предоставление данных для формирования пакета документов на
                        получение субсидий, получаемых из государственного бюджета. <br /> 1.5.31. Сверка с ИФНС, СФР за период
                        текущего сопровождения (запрос и анализ актов сверки и выписок по расчету с бюджетом, справок о исполнении
                        обязанности по уплате налогов и взносов, справок об отсутствии задолженности). Предоставляется при
                        подключении к Личному кабинету СБИС.
                        <br /> 1.5.32. Подготовка ответов на требование ФНС, СФР за период текущего сопровождения. <br /> 1.5.33.
                        Подготовка обязательных типовых форм отчетности в контролирующие органы для Заказчиков, которые обязаны их
                        сдавать в соответствии с действующим законодательством: - Декларация по налогу на добавленную стоимость; -
                        Декларация по налогу на прибыль; - Декларация по налогу на имущество;- - Декларация по налогу,
                        уплачиваемому в связи с применением упрощенной системы налогообложения (Декларация по УСН); - Книгу учета
                        доходов; - Книгу учета доходов и расходов; - Декларация по форме 3-НДФЛ для ИП - Книга учета доходов и
                        расходов для ИП на ОСНО - Декларация по косвенным налогам; - Упрощенный бухгалтерский баланс; - Упрощенный
                        отчет о финансовых результатах; - Единая (упрощенная) налоговая декларация; - Журнал учета счетов-фактур;
                        - Налоговый расчет о суммах, выплаченных иностранным организациям доходов и удержанных налогов; -
                        Уведомление об исчисленных суммах налогов, авансовых платежей по налогам, сборов, страховых взносов; -
                        Формы Росстат (входят в тариф «Премиальный» и «Премиальный+»). <br /> 1.5.34. Предоставление данных о
                        финансовых результатах, оборотах по счетам, оборотах по субконто, общих суммах выплат или перечислений с
                        расчетных счетов или кассы, сумме уплаченных налогов и/или сборов, суммах произведенных расходов или
                        полученных доходах и т.п., в виде стандартных отчетов, предусмотренных актуальной конфигурацией
                        программного обеспечения 1С: Предприятие 8.3. MBA_agreement_ver.10 <br /> 1.5.35. Расчет ежемесячной суммы
                        Единого налогового платежа, подготовка платёжного поручения на пополнение Единого налогового счета.
                        <span style={{ opacity: 0 }}>lorem lorem lorem lorem lorem</span>
                      </>
                    }
                    modalWhite={true}
                  />
                </div>
                <div className={styles.column}>
                  <Dropdown title="Таблица перечня дополнительных услуг" modalTable={true} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
