import React from "react";

import Layout from "../components/Layout/Layout";
import Offers from "../components/pages/Offers/Offers";
import Consult from "../components/pages/Consult/Consult";

const OffersPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Offers />
      <Consult />
    </Layout>
  );
};

export default OffersPage;
