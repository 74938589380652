import React from "react";

import Layout from "../components/Layout/Layout";

import Military from "../components/pages/HR/Military/Military";
import HrRecords from "../components/pages/HR/HrRecords/HrRecords";
import Consult from "../components/pages/Consult/Consult";
import Info from "../components/Info/Info";

const HRPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Info mode="hr" />
      <Military />
      <HrRecords />
      <Consult />
    </Layout>
  );
};

export default HRPage;
